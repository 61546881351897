import { Outlet, useNavigate } from 'react-router-dom'
import { useApp, useNotAuthenticatedRedirects } from 'Hooks'
import { TermsAndConditions } from 'Components'

export const NotAuthLayout = ({ hasTermsAndConditions = true }) => {
  const navigate = useNavigate()
  const { productName } = useApp()
  useNotAuthenticatedRedirects()

  return (
    <div className="container relative grid min-h-svh grid-cols-1 items-center justify-center max-md:py-[4vh] md:max-w-none md:grid-cols-2 md:p-0">
      <div className="relative hidden h-full flex-col p-10 text-white md:flex bg-primary">
        <div className="flex flex-col items-center">
          <button onClick={() => navigate('/')} className="bg-transparent border-none p-0 mb-4">
            <img src="/img/logo-dark.png" alt="Logo" className="h-12 w-auto object-contain" />
          </button>
          <h2 className="text-2xl font-semibold mb-2">Aplicația Factcurier {productName}</h2>
          <p className="text-center text-sm mb-8">Bine ați venit</p>
        </div>
        <div className="relative mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              &ldquo;Simplitatea este cheia unei vieți plăcute. Noi ne ocupăm de SPV pentru ca dumneavoastră să vă puteți
              concentra asupra afacerii.&rdquo;
            </p>
            <footer className="text-sm">
              <strong>Echipa Factcurier</strong>
            </footer>
          </blockquote>
        </div>
      </div>
      <div className="w-full space-y-8">
        <button onClick={() => navigate('/')} className="p-0 bg-transparent border-none cursor-pointer md:hidden">
          <img src="/img/logo-large.png" alt="Logo" width={150} className="h-12 object-contain w-min" />
        </button>
        <Outlet />
        {hasTermsAndConditions && <TermsAndConditions />}
      </div>
    </div>
  )
}
