import { configureStore, createSlice } from '@reduxjs/toolkit'
import { IS_DEBUG } from 'Constants'

// @INSTALL all stores from all installed packages HERE
import locales from '../lib/Locales/store'
import lists from '../lib/Lists/store'
import auth from '../lib/Auth/store'
import forms from '../lib/Forms/store'

// import { reducerActions as appActions } from '../lib/Base/store'

// @INSTALL all reducerActions from all installed packages HERE
import { reducerActions as localesActions } from '../lib/Locales/store'
import { reducerActions as listsActions } from '../lib/Lists/store'
import { reducerActions as authActions } from '../lib/Auth/store'
import { reducerActions as formsActions } from '../lib/Forms/store'

import { baseInitialState, baseReducers } from '../lib/Base/store'
import { commonInitialState, commonReducers } from '../site-common/store'
import { adminInitialState, adminReducers } from '../site-admin/store'
import { devInitialState, devReducers } from '../site-dev/store'
import { appInitialState, appReducers } from '../site-app/store'

const slice = createSlice({
  name: 'app',
  initialState: {
    ...baseInitialState,
    ...commonInitialState,
    ...adminInitialState,
    ...devInitialState,
    ...appInitialState
  },
  reducers: {
    ...baseReducers,
    ...commonReducers,
    ...adminReducers,
    ...devReducers,
    ...appReducers
  }
})

export const appActions = slice.actions
const app = slice.reducer

// form the store by combining the slices
export const Store = configureStore({
  reducer: {
    app,
    locales,
    lists,
    auth,
    forms
  },
  devTools: IS_DEBUG
})

// just merge them all
const reducerActions = {
  ...appActions,

  ...localesActions,
  ...listsActions,
  ...authActions,
  ...formsActions
}

// attach the dispatcher and re-export them all as hash
const actionsNames = Object.keys(reducerActions)
export const ReducerActions = actionsNames.reduce((acc, actionName) => {
  return {
    ...acc,
    [actionName]: (payload) => {
      if (IS_DEBUG) console.info('ReducerActions:', actionName, payload)
      Store.dispatch(reducerActions[actionName](payload))
    }
  }
}, {})
