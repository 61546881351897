import { useEffect, StrictMode } from 'react'
import { Actions, State } from 'Store'
import { IS_DEBUG } from 'Constants'

// the debug provider well print some info to the console
export const DebugProvider = ({ children }) => {
  useEffect(() => {
    if (IS_DEBUG) {
      window.State = () => State()
      window.Actions = Actions
    }
  }, [])

  const isDebug = IS_DEBUG && process.env.REACT_STRICT_MODE === 'true'
  return isDebug ? <StrictMode>{children}</StrictMode> : children
}
