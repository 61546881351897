export const DeleteAccount = () => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Dezactivează contul</h3>
        <p>Înainte de a dezactiva contul, trebuie să nu fiți proprietarul niciunei firme.</p>
      </div>
    </div>
  )
}
