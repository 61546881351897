export const WhoAmI = () => {
  // Import version from package.json
  const version = process.env.REACT_APP_VERSION || require('../../../../package.json').version

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <div style={{ fontSize: '4rem', color: '#4CAF50', marginBottom: '1rem' }}>✓</div>
      <h1>System is up and running</h1>
      <p>Version: {version}</p>
    </div>
  )
}
