import { cloneElement, Fragment } from 'react'

import { useList } from 'Hooks'

// the container for wrking with lists
export const ListContainer = ({ list, ids, children, sortKey, isDesc }) => {
  const uList = useList(list, sortKey, isDesc)
  ids = ids || uList
  return (
    <>
      {ids.map((id, i) => (
        <Fragment key={i}>{cloneElement(children, { id })}</Fragment>
      ))}
    </>
  )
}
