import { FiExternalLink } from 'react-icons/fi'
import { Actions } from 'Store'

const ACCOUNT_TYPE_MAPPING = {
  ADMINUSER: 'Platforma de administrare',
  DEVELOPER: 'Platforma de dezvoltare',
  EMPLOYEE: 'Firma'
}

export const NewAccountNotification = ({ id, accountType, authLink, companyName, appName, invitedByName }) => {
  const getPlatformName = () => {
    switch (accountType) {
      case 'EMPLOYEE':
        return companyName
      case 'DEVELOPER':
        return appName
      default:
        return ACCOUNT_TYPE_MAPPING[accountType]
    }
  }

  return (
    <div className="flex items-center gap-3 w-full py-2">
      <div className="flex-1 min-w-0 space-y-2">
        <div className="space-y-1.5">
          <p className="text-sm leading-tight">
            Ai fost invitat de <span className="font-medium">{invitedByName}</span> să te alături la{' '}
            <span className="text-primary font-medium">{getPlatformName()}</span>
          </p>
          <p className="text-xs text-muted-foreground">{ACCOUNT_TYPE_MAPPING[accountType]}</p>
        </div>
      </div>
      <div className="shrink-0">
        <button
          onClick={() => {
            Actions.deleteNotifications([id])
            window.open(authLink, '_blank', 'noopener,noreferrer')
          }}
          className="group p-1.5 hover:bg-primary/10 active:bg-primary/20 rounded-md transition-all duration-200"
          title="Deschide invitația"
        >
          <FiExternalLink size={18} className="text-muted-foreground group-hover:text-primary transition-colors stroke-[2.5px]" />
        </button>
      </div>
    </div>
  )
}
