import { FiCheck, FiMapPin, FiX } from 'react-icons/fi'
import { getTimeUntilExpiry } from 'Utils'

export const CompanyInvitationNotification = ({
  cif,
  companyName,
  createdAt,
  expiresAt,
  invitedByName,
  invitedByEmail,
  city,
  county
}) => (
  <div className="flex items-center gap-3 w-full py-2">
    <div className="flex-1 min-w-0 space-y-2">
      <div className="space-y-1.5">
        <p className="text-sm leading-tight">
          Ai fost invitat de <span className="font-medium">{invitedByName}</span> cu emailul{' '}
          <span className="text-muted-foreground">{invitedByEmail}</span> să te alături companiei
        </p>
        <p className="flex items-baseline gap-2">
          <span className="text-xs font-medium text-muted-foreground">CIF: {cif}</span>
          <span className="text-primary text-lg font-medium">{companyName}</span>
        </p>
        <p className="flex items-center gap-1.5 text-xs text-muted-foreground">
          <FiMapPin size={14} className="stroke-[2px]" />
          <span>
            {city}, {county}
          </span>
        </p>
      </div>
      <div className="flex items-center gap-3 text-xs">
        <span className="text-muted-foreground">
          {new Date(createdAt).toLocaleDateString('ro-RO', {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </span>
        <span className="text-destructive font-medium flex items-center gap-1">
          <span className="h-1 w-1 rounded-full bg-destructive/50" />
          Expiră în {getTimeUntilExpiry(expiresAt)}
        </span>
      </div>
    </div>
    <div className="flex gap-2 shrink-0">
      <button
        onClick={(e) => {
          e.stopPropagation()
          // Handle accept logic here
        }}
        className="group p-1.5 hover:bg-success/10 active:bg-success/20 rounded-md transition-all duration-200"
        title="Acceptă invitația"
      >
        <FiCheck size={18} className="text-muted-foreground group-hover:text-success transition-colors stroke-[2.5px]" />
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation()
          // Handle deny logic here
        }}
        className="group p-1.5 hover:bg-destructive/10 active:bg-destructive/20 rounded-md transition-all duration-200"
        title="Refuză invitația"
      >
        <FiX size={18} className="text-muted-foreground group-hover:text-destructive transition-colors stroke-[2.5px]" />
      </button>
    </div>
  </div>
)
