// will decode a base 64 string
export const getObjectFromBase64 = (base64String) => {
  if (!base64String) return {}
  try {
    const decodedString = decodeURIComponent(atob(base64String))
    return JSON.parse(decodedString) || {}
  } catch {
    return {}
  }
}

export const encodeObjectToBase64 = (object) => {
  if (!object) return ''
  const stringifiedObject = JSON.stringify(object)
  const encodedString = btoa(encodeURIComponent(stringifiedObject))
  return encodedString
}
