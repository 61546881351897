import { Section, Switch } from 'Components'

const notifications = [
  {
    name: 'Invoices',
    value: true
  },
  {
    name: 'Marketing',
    value: false
  }
]

export const PushNotifications = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Notifications</h3>

      <Section>
        {notifications.map(({ name, value }) => (
          <div className="flex items-center justify-between gap-x-4 rounded-xl px-3 py-4">
            <p>{name}</p>
            <Switch checked={value} onCheckedChange={() => {}} aria-readonly />
          </div>
        ))}
      </Section>
    </>
  )
}
