import DOMPurify from 'dompurify'
import { HTML_TAG_DICT } from 'Constants'
import { convert } from 'html-to-text'

const sanitizeText = (text, isEscaped, skipSanitization) => {
  if (!text || typeof text !== 'string') return ''
  if (isEscaped)
    text = text
      .split('')
      .map((char) => HTML_TAG_DICT[char] || char)
      .join('')
  text = skipSanitization || typeof window === 'undefined' ? text : DOMPurify.sanitize(text)
  return text
}

export const sanitizeHTML = sanitizeText

export const dangerousHTML = (text, isEscaped, skipSanitization) => {
  text = sanitizeText(text, isEscaped, skipSanitization)
  return text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : null
}

export const convertHTMLToText = (html) => convert(html)

export const replaceTextLines = (text) => (text || '').replace(/(?:\r\n|\r|\n)/g, '<br /><br />')

export const removeEmptyLines = (text) => (text || '').trim().replace(/<p><br><\/p>/g, '')

export const removeHTMLTags = (strToSanitize) => {
  let myHTML = new DOMParser().parseFromString(strToSanitize, 'text/html')
  return myHTML.body.textContent || ''
}

// Utility function to remove accents from strings
export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
