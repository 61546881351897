// will extract the routes from the pathname
export const getLocationFromRoutes = (pathname) => {
  pathname = pathname || ''
  pathname = pathname.substring(1)
  return pathname.split('/').filter(Boolean)
}

// will extract all query params from search
// ?f=1111&g=2222&sdfdsfs=235324562356 -> { f: 1111, g: 2222, sdfdsfs: 235324562356 }
// ??f=1 -> {}
export const getLocationFromSearch = (search) => {
  search = search || ''
  search = search.substring(1)
  search = search.split('&')
  return search.reduce((acc, s) => {
    const [key, value] = (s || '').split('=')
    return value ? { ...acc, [key]: value } : acc
  }, {})
}

// used in env vars to convert string to boolean
export const getBooleanVarFromText = (text) => {
  text = (text || '').toLowerCase()
  if (text.toLowerCase() === 'false') text = ''
  return !!text
}

// will get only the first route from the pathname
export const getRouteFromPathname = (pathname) => {
  pathname = pathname || ''
  pathname = pathname.substring(1)
  return pathname.split('/')[0] || 'home'
}
