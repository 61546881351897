import { Section } from 'Components'
import { SUPPORTED_LANGUAGES } from 'Constants'
import { FiCheck } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const LanguageSelector = () => {
  const currentLanguage = useSelector((state) => state.locales.language)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Choose a language</h3>
      <Section>
        {SUPPORTED_LANGUAGES.map(({ name, code, locale }, i) => (
          <button
            key={i}
            onClick={() => Actions.setLocale(locale)}
            className="flex w-full items-center justify-between gap-x-4 rounded-xl px-3 py-4 text-left hover:bg-secondary-background"
          >
            {name}
            {currentLanguage === code && <FiCheck className="size-5" />}
          </button>
        ))}
      </Section>
    </>
  )
}
