import {
  _callAPI,
  registerCompanyQuery,
  getRomanianCompanyByCUIQuery,
  searchRomanianCompaniesQuery,
  selectCompanyQuery,
  inviteEmployeeQuery
} from 'API'

export const _registerCompany = async (companyData) => {
  if (!companyData) return null
  const { error, registerCompany: result } =
    (await _callAPI({
      query: registerCompanyQuery,
      variables: { companyData },
      allowPublic: true
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _selectCompany = async (companyUserId) => {
  if (!companyUserId) return null
  const { error, selectCompany: result } =
    (await _callAPI({
      query: selectCompanyQuery,
      variables: { companyUserId }
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _getRomanianCompanyByCUI = async (cif) => {
  if (!cif) return null
  const { error, getRomanianCompanyByCUI: result } =
    (await _callAPI({
      query: getRomanianCompanyByCUIQuery,
      variables: { cif }
    })) || {}
  return error ? { error } : result || null
}

export const _searchRomanianCompanies = async (text) => {
  if (!text) return []
  const { error, searchRomanianCompanies: result } =
    (await _callAPI({
      query: searchRomanianCompaniesQuery,
      variables: { text },
      allowPublic: true
    })) || {}
  return error ? { error } : result || []
}

export const _inviteEmployee = async () => {
  const { inviteEmployee: result } = (await _callAPI({ query: inviteEmployeeQuery })) || {}
  return result || null
}
