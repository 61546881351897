import { createSlice } from '@reduxjs/toolkit'
import { getListHash } from 'Utils'
import { initialLists } from '../../config/lists.js'

const initialState = {
  ...initialLists
}

const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    _populateList: (state, action) => {
      const { list, items, idKey } = action.payload || {}
      if (!list) return
      state[list] = getListHash(items, idKey)
    },
    _upsertListItem: (state, action) => {
      const { list, item, idKey } = action.payload || {}
      if (!list || !item?.[idKey || 'id']) return
      state[list][item[idKey || 'id']] = item
    },
    _deleteListItem: (state, action) => {
      const { list, id } = action.payload || {}
      if (!list || !id) return
      delete state[list][id]
    },
    _resetLists: (state) => {
      Object.assign(state, initialState)
    }
  }
})

export const reducerActions = slice.actions

export default slice.reducer
