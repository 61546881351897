import { useSelector } from 'react-redux'
import { Avatar, AvatarFallback, AvatarImage, Section } from 'Components'
import { FiShare2, FiChevronRight } from 'react-icons/fi'
import { Actions } from 'Store'
import { Link, useNavigate } from 'react-router-dom'
import { getInitialsByName, route } from 'Utils'

const Employee = ({ employee }) => {
  // const userId = useSelector((state) => state.auth.account?.userId)
  return (
    <li className="rounded-xl p-2 hover:bg-secondary-background">
      <Link
        to={route('settings.company.team.employee', { userId: employee.userId })}
        className="flex items-center justify-between gap-x-6"
      >
        <div className="flex min-w-0 gap-x-4">
          <Avatar>
            <AvatarImage src={employee.user?.avatarUrl} alt="User avatar" />
            <AvatarFallback>{getInitialsByName(employee.user?.userName)}</AvatarFallback>
          </Avatar>
          <div className="min-w-0 flex-auto">
            <p className="text-sm/6 font-semibold">{employee.user?.userName}</p>
            <p className="flex text-xs/5 text-gray-500">Last active: </p>
          </div>
        </div>
        <FiChevronRight aria-hidden="true" className="size-5 flex-none text-gray-400" />
      </Link>
    </li>
  )
}

export const CompanyEmployees = () => {
  const navigate = useNavigate()

  const employees = useSelector((state) => state.auth.account?.company?.users)

  const share = async () => {
    const url = await Actions.inviteEmployee()
    navigate('/modals/share', {
      state: { url, title: 'Adauga utilizator' }
    })
  }

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Echipa</h3>

      <Section>
        <button onClick={share} className="flex w-full items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
          <FiShare2 className="size-5" />
          <span className="grow text-left">Invită colegi</span>
        </button>
      </Section>

      <Section title="Angajați actuali">
        <ul>
          {employees?.map((employee, i) => (
            <Employee key={i} employee={employee} />
          ))}
        </ul>
      </Section>

      <Section title="Pending Invitations">
        <ul>
          {employees?.map((employee, i) => (
            <Employee key={i} employee={employee} />
          ))}
        </ul>
      </Section>
    </>
  )
}
