import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProduct, getProductName } from 'Utils'

export const useApp = () => {
  const appState = useSelector((state) => state.app)
  const isLoadingLocale = useSelector((state) => state.locales.isLoadingLocale)
  const isAuthLoading = useSelector((state) => state.auth.isLoadingAuth)
  const isLoading = appState.isLoadingApp || isLoadingLocale || isAuthLoading
  const product = getProduct().toLowerCase()
  const productName = getProductName()
  const theme = appState.theme
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)')
  const realTheme = theme === 'system' ? (prefersDark ? 'dark' : 'light') : theme
  return { ...appState, isLoading, product, productName, theme: realTheme }
}

export const useMediaQuery = (query) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    function onChange(event) {
      setValue(event.matches)
    }

    const result = matchMedia(query)
    result.addEventListener('change', onChange)
    setValue(result.matches)

    return () => result.removeEventListener('change', onChange)
  }, [query])
  return value
}
