import { Tabs, TabsList, TabsTrigger } from 'Components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export const AdminSettingsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const activeTab = location.pathname === '/settings' ? 'settings' : location.pathname.split('/').pop()
  const handleTabChange = (value) => {
    navigate(value === 'settings' ? '/settings' : `/settings/${value}`)
  }

  return (
    <div className="container mx-auto max-w-3xl p-6">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="mb-8">
          <TabsTrigger value="settings">Setǎri generale</TabsTrigger>
          <TabsTrigger
            value="adminlogs"
            className="border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:bg-transparent data-[state=active]:shadow-none rounded-none"
          >
            Activitate admin
          </TabsTrigger>
        </TabsList>
        <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
          <Outlet />
        </div>
      </Tabs>
    </div>
  )
}

export const AdminSettings = () => {
  return <div>AdminSettings</div>
}
