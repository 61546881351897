export const adminUserFields = `{
  avatarUrl
  email
  userName
  roles
  lastLoginAt
  isBlocked
}`

export const userFields = `{
  userId
  userName
  email
  createdAt
  lastLoginAt
  hasLinkedin
  hasGoogle
  avatarUrl
}`

export const sessionFields = `{
  jwtId
  hasCompany
  hasApp
  hasUser
  hasInvitation
  accountId
  accountType
  isAuthLink
  isEmailPin
  userId
  email
  createdAt
  refreshedAt
  expiresAt
  ipAddress
  location
  browser
  os
  deviceType
}`

export const activityLogFields = `{
  id
  ts
  type
  data
  caller {
   name
   avatarUrl
  }
}`
