import { CompanySelector, NotificationsMenu, ThemeToggle, UserMenu } from 'Components'
import { useIsMobile } from 'Hooks'
import { cn } from 'Utils'

export const AppHeader = () => {
  const isMobile = useIsMobile()

  return (
    <header className={cn('mx-auto flex h-16 w-full max-w-screen-xl items-center gap-8 p-6', !isMobile && 'justify-end')}>
      {isMobile && <CompanySelector />}

      <div className="flex items-center gap-4 max-md:hidden">
        <ThemeToggle />
        <NotificationsMenu />
        <UserMenu />
      </div>
    </header>
  )
}
