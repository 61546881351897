import { useSelector } from 'react-redux'
import { Button, Input, Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from 'Components'
import { Actions } from 'Store'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useState } from 'react'

const accountFormSchema = z.object({
  userName: z
    .string()
    .trim()
    .min(2, {
      message: 'Name must be at least 2 characters.'
    })
    .max(30, {
      message: 'Name must not be longer than 30 characters.'
    })
})

export const UserProfile = () => {
  const user = useSelector((state) => state.auth.account?.user)
  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = {
    userName: user.userName
  }

  const form = useForm({
    resolver: zodResolver(accountFormSchema),
    defaultValues
  })

  const onSubmit = async (data) => {
    setIsLoading(true)
    await Actions.saveUserProfile(data)
    setIsLoading(false)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">Personal details</h1>

        {/* Name Section */}
        <FormField
          control={form.control}
          name="userName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nume</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="surname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Surname</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button isLoading={isLoading} type="submit">
          Update account
        </Button>
      </form>
    </Form>
  )
}
