import { useSelector } from 'react-redux'
import { memoize } from 'proxy-memoize'

export const useListItem = (list, id) => {
  return useSelector((state) => state.lists?.[list]?.[id])
}

export const useList = (list, sortKey, isDesc) => {
  sortKey = sortKey || 'orderIndex'
  let listObj = useSelector(memoize((state) => (list ? Object.values(state.lists?.[list]) : [])))
  return listObj
    .sort((valuea, valueb) => ((isDesc ? valuea[sortKey] > valueb[sortKey] : valuea[sortKey] < valueb[sortKey]) ? -1 : 1))
}

export const useListCount = (list) => {
  return useSelector((state) => Object.keys(state.lists?.[list] || {}).length)
}
