import { Toaster, useToast } from 'Components'
import { useEffect } from 'react'
import { Actions } from 'Store'
import { useApp } from 'Hooks'
import { cn } from 'Utils'

export const MainToastProvider = ({ children }) => {
  return (
    <>
      {children}

      <Toaster
        className="z-50"
        toastOptions={{
          className: 'border border-border shadow-lg'
        }}
      />
    </>
  )
}

export const ToastContainer = () => {
  const { toast } = useToast()
  const { showToast, toastMessage, toastVariant, toastDuration } = useApp()
  useEffect(() => {
    if (showToast && toastMessage) {
      toast({
        description: toastMessage,
        variant: toastVariant,
        duration: toastDuration,
        className: cn('border border-border', {
          'bg-destructive text-destructive-foreground': toastVariant === 'destructive',
          'bg-background text-foreground': toastVariant !== 'destructive'
        })
      })
      // Hide the toast in Redux after showing it
      setTimeout(() => {
        Actions.hideToast()
      }, toastDuration)
    }
  }, [showToast, toastMessage, toastVariant, toastDuration, toast])

  return null
}
