import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { getObjectFromBase64, getQueryParamsKey, setQueryParam } from 'Utils'

// use it on the main layout outside the authentication routes
export const useNotAuthenticatedRedirects = () => {
  const navigate = useNavigate()
  const hasAccount = useSelector((state) => !!state.auth.account.accountId)
  const isAlreadyLoginRoute = useLocation().pathname.startsWith('/login')

  const hasAppOrInvitation = useSelector(
    (state) => state.auth.account.invitation || state.auth.account.app || state.auth.account.appUserId
  )

  const hasCompanyOrInvitation = useSelector(
    (state) => state.auth.account.invitation || state.auth.account.company || state.auth.account.companyUserId
  )
  useEffect(() => {
    if (hasAccount) navigate('/')
    if (isAlreadyLoginRoute) return
    else if (!hasAppOrInvitation && !hasCompanyOrInvitation) navigate('/register')
    else navigate('/login')
  }, [navigate, hasAccount, isAlreadyLoginRoute, hasAppOrInvitation, hasCompanyOrInvitation])
}

// use it on the main layout inside the authentication routes
export const useAuthenticatedRedirects = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const hasAccount = useSelector((state) => !!state.auth.account.accountId)
  const hasInvitation = useSelector((state) => !!state.auth.account.invitation)
  useEffect(() => {
    if (location.pathname === '/modals/invite') return
    if (hasInvitation) navigate('/modals/invite')
  }, [navigate, hasInvitation, location.pathname])

  useEffect(() => {
    if (!hasAccount) navigate('/login')
  }, [navigate, hasAccount])
}

// use it on the main layout outside the authentication routes
export const useAuthInit = () => {
  useEffect(() => {
    const casesForInitialAuth = async () => {
      const token = getQueryParamsKey('token')
      const error = getQueryParamsKey('error')

      if (token) {
        setQueryParam('token')
        await Actions.authenticateWithToken(token)
      } else if (error) {
        setQueryParam('error')
        Actions.setErrorAuth(getObjectFromBase64(error))
      } else {
        await Actions.getMe()
      }
      Actions.setIsLoadingApp(false)
    }
    casesForInitialAuth()
  }, [])
}
