import { useEffect } from 'react'
import { Actions } from 'Store'
import { CentralLoader, AdminRouter, Main } from 'Components'
import { useAuthInit } from 'Hooks'

const useAdminLocalStorage = () => {}

export const AdminApp = () => {
  useEffect(() => {
    const loadData = async () => {
      await Actions.loadAdminInitialData()
      Actions.loadAdminScripts()
    }
    loadData()
  }, [])
  useAdminLocalStorage()
  useAuthInit()

  return (
    <Main loader={<CentralLoader />}>
      <AdminRouter />
    </Main>
  )
}
