export const renderChange = (oldValue, newValue, icon) => (
  <div className="flex items-center gap-2 py-1">
    {icon && <span className="text-gray-400">{icon}</span>}
    <span className="line-through text-red-500 dark:text-red-400">{oldValue}</span>
    <span className="text-green-500 dark:text-green-400">{newValue}</span>
  </div>
)

export const booleanChanges = (value, Icon, label) => {
  if (value?.length === 2 && value[0] !== value[1]) {
    return (
      <div className="flex items-center gap-2 py-1">
        <Icon className="h-4 w-4 text-gray-400" />
        <span className={value[1] ? 'text-green-500 dark:text-green-400' : 'text-red-500 dark:text-red-400'}>
          {value[1] ? label[0] : label[1]}
        </span>
      </div>
    )
  }
  return null
}

export const renderRolesChanges = (oldRoles, newRoles) => {
  return (
    <>
      <div className="flex gap-2">
        {oldRoles.map((role) => (
          <span
            key={role}
            className="rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-600 dark:bg-red-900 dark:text-red-300 line-through"
          >
            {role}
          </span>
        ))}
      </div>
      <div className="flex gap-2">
        {newRoles.map((role) => (
          <span
            key={role}
            className="rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-600 dark:bg-green-900 dark:text-green-300"
          >
            {role}
          </span>
        ))}
      </div>
    </>
  )
}
