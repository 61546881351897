import { Avatar, AvatarFallback, AvatarImage, ResponsiveDropdown } from 'Components'
import { getInitialsByName } from 'Utils'
import { Actions } from 'Store'
import { useSelector } from 'react-redux'

export const ProfileHeader = () => {
  const user = useSelector((state) => state.auth.account.user)

  if (!user?.avatarUrl) {
    return <Trigger user={user} />
  }

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0]
    if (file && file.type.startsWith('image/')) {
      const contentType = file.type
      const reader = new FileReader()
      reader.onload = async (event) => {
        const base64String = event.target?.result
        Actions.saveUserProfile({ avatarFile: { base64String, contentType } })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <ResponsiveDropdown
      trigger={<Trigger user={user} />}
      items={[
        { label: 'Change image', onClick: () => console.log('das') },
        { label: 'Remove image', onClick: () => console.log('das') }
      ]}
    />
  )
}

const Trigger = ({ user }) => {
  return (
    <div className="mt-5 flex flex-col items-center">
      <div className="relative cursor-pointer">
        <Avatar className="mx-auto size-16">
          <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
          <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
        </Avatar>

        {/* <input type="file" className="absolute inset-0 appearance-none opacity-0" accept="image/*" onChange={handleFileChange} /> */}

        <p className="mt-2 text-xs">Edit picture</p>
      </div>
    </div>
  )
}
