import { Tabs, TabsList, TabsTrigger } from 'Components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export const DevSettingsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const activeTab = location.pathname === '/settings' ? 'settings' : location.pathname.split('/').pop()
  const handleTabChange = (value) => {
    navigate(value === 'settings' ? '/settings' : `/settings/${value}`)
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col gap-6">
        <h1 className="text-2xl font-bold">Setări firmă</h1>
        <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
          <TabsList className="border-b rounded-none w-full justify-start bg-transparent">
            <TabsTrigger
              value="team"
              className="border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:bg-transparent data-[state=active]:shadow-none rounded-none"
            >
              Dezvoltatori
            </TabsTrigger>
            <TabsTrigger
              value="applogs"
              className="border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:bg-transparent data-[state=active]:shadow-none rounded-none"
            >
              Activitate aplicație
            </TabsTrigger>
            <TabsTrigger
              value="notifications"
              className="border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:bg-transparent data-[state=active]:shadow-none rounded-none"
            >
              Setări notificări
            </TabsTrigger>
            <TabsTrigger
              value="settings"
              className="border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:bg-transparent data-[state=active]:shadow-none rounded-none"
            >
              Setări generale
            </TabsTrigger>
          </TabsList>
          <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
            <Outlet />
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export const DevSettings = () => {
  return <div>DevSettings</div>
}
