import { Section } from 'Components'
import { APP_SETTINGS_ITEMS } from 'Constants'
import { useIsMobile } from 'Hooks'
import { Link, useNavigate } from 'react-router-dom'
import { route } from 'Utils'

export const MobileSettings = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  if (!isMobile) navigate(route('settings.company'))
  return (
    <div>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Settings</h3>
      {APP_SETTINGS_ITEMS.map(({ items }, index) => (
        <Section key={index}>
          {items.map(({ icon: Icon, to, name }, i) => (
            <Link key={i} to={route(to)} className="flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
              <Icon className="size-5" />
              <span className="grow">{name}</span>
            </Link>
          ))}
        </Section>
      ))}
    </div>
  )
}
