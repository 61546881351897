import { EMOJIS_REGEX } from 'Constants'

export const getInitialsByName = (name, limit = 2) => {
  if (!name) return ''
  const clearedFromEmojis = name.replace(new RegExp(EMOJIS_REGEX, 'ug'), '')

  let initials = ''
  const nameParts = clearedFromEmojis.split(' ')
  const sliceAt = limit || nameParts.length

  nameParts.slice(0, sliceAt).forEach((word) => {
    if (word) {
      initials += word[0].toUpperCase()
    }
  })

  return initials
}

export const getLowerCaseIndexOfArray = (array, value) => {
  value = typeof value === 'string' ? value : ''
  let findIndex = (array || []).filter(Boolean).map((item) => item.toLowerCase())
  findIndex = findIndex.indexOf(value.toLowerCase())
  return findIndex === -1 ? '' : array[findIndex]
}

export const flattenMatrix = (matrix) => {
  return matrix.reduce((acc, val) => acc.concat(val), [])
}
