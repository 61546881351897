import { adminUserFields, userFields, sessionFields, activityLogFields } from 'API'

export const getAdminUsersQuery = `
  query getAdminUsers {
    getAdminUsers ${adminUserFields}
  }
`

export const getAdminHumanUsersQuery = `
  query getAdminHumanUsers {
    getAdminHumanUsers ${userFields}
  }
`

export const getAdminSessionsQuery = `
  query getAdminSessions {
    getAdminSessions ${sessionFields}
  }
`

export const getAdminActivityLogsQuery = `
  query getAdminActivityLogs {
    getAdminActivityLogs ${activityLogFields}
  }
`

export const getAdminAppsQuery = `
  query getAdminApps {
    getAdminApps { 
      appUserId
      appId
      appName
      isApproved
    }
  }
`

export const saveAdminUserQuery = `
  mutation saveAdminUser($email: String!, $userName: String, $roles: [String], $isBlocked: Boolean) {
    saveAdminUser(email: $email, userName: $userName, roles: $roles, isBlocked: $isBlocked) ${adminUserFields}
  }
`

export const deleteAdminUserQuery = `
  mutation deleteAdminUser($email: String!) {
    deleteAdminUser(email: $email)
  }
`

export const sendContactFormQuery = `
  mutation sendContactForm($contactData: ContactFormData!) {
    sendContactForm(contactData: $contactData)
  }
`

export const saveAdminAppQuery = `
  mutation saveAdminApp($appData: AppAdminPayload!) {
    saveAdminApp(appData: $appData) {
      appUserId
      isApproved
    }
  }
`
export const approveOrDenyAppQuery = `
  mutation approveOrDenyApp($appId: ID!, $isApproved: Boolean!) {
    approveOrDenyApp(appId: $appId, isApproved: $isApproved) 
  }
`
