import { useSelector } from 'react-redux'
import { Button, Time, Popover, PopoverContent, PopoverTrigger } from 'Components'
import { FiEdit2, FiTrash2, FiX, FiShare, FiMail, FiMessageCircle, FiLink } from 'react-icons/fi'
import { useState } from 'react'
import { Actions } from 'Store'

export const AppDevelopers = () => {
  const [inviteUrl, setInviteUrl] = useState(null)
  const [copied, setCopied] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)

  const developers = useSelector((state) => state.auth.account?.app?.users)
  const userId = useSelector((state) => state.auth.account?.userId)

  const handleGetInvite = async () => {
    const url = await Actions.inviteDeveloper()
    if (!url) return
    setInviteUrl(url)
    setShareOpen(true) // Open sharing options when URL is generated
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteUrl)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const handleShare = async (method) => {
    switch (method) {
      case 'native':
        if (navigator?.share) {
          try {
            await navigator.share({
              title: 'Invitație acces aplicație',
              text: 'Te invit să te alături companiei noastre',
              url: inviteUrl
            })
          } catch {
            //
          }
        }
        break
      case 'whatsapp':
        window.open(`whatsapp://send?text=${encodeURIComponent('Te invit să faci parte din aplicație: ' + inviteUrl)}`)
        break
      case 'email':
        window.open(
          `mailto:?subject=${encodeURIComponent('Invitație acces aplicație')}&body=${encodeURIComponent('Te invit să faci parte din aplicație: ' + inviteUrl)}`
        )
        break
      case 'copy':
        copyToClipboard()
        break
      default:
        break
    }
    setShareOpen(false)
  }

  return (
    <div className="py-4">
      <div className="bg-white rounded-lg border border-gray-100 p-4 mb-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
              <FiShare className="h-5 w-5 text-primary" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Invită colegi</h3>
              <p className="text-sm text-gray-600">Trimite invitația prin metoda preferată</p>
            </div>
          </div>

          {!inviteUrl ? (
            <Button onClick={handleGetInvite} className="w-full sm:w-auto bg-primary hover:bg-primary/90">
              Generează link invitație
            </Button>
          ) : (
            <div className="space-y-4">
              <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-3">
                <span className="text-sm text-gray-600 flex-1 truncate">{inviteUrl}</span>
                <Popover open={shareOpen} onOpenChange={setShareOpen}>
                  <PopoverTrigger asChild>
                    <Button variant="outline" size="sm" className="h-8 gap-2">
                      <FiShare className="h-4 w-4" />
                      <span className="hidden sm:inline">Distribuie</span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-56 p-2">
                    <div className="space-y-1">
                      {navigator.share && (
                        <Button variant="ghost" className="w-full justify-start gap-2 h-9" onClick={() => handleShare('native')}>
                          <FiShare className="h-4 w-4" />
                          <span>Distribuie...</span>
                        </Button>
                      )}
                      <Button variant="ghost" className="w-full justify-start gap-2 h-9" onClick={() => handleShare('whatsapp')}>
                        <FiMessageCircle className="h-4 w-4" />
                        <span>WhatsApp</span>
                      </Button>
                      <Button variant="ghost" className="w-full justify-start gap-2 h-9" onClick={() => handleShare('email')}>
                        <FiMail className="h-4 w-4" />
                        <span>Email</span>
                      </Button>
                      <Button variant="ghost" className="w-full justify-start gap-2 h-9" onClick={() => handleShare('copy')}>
                        <FiLink className="h-4 w-4" />
                        <span>{copied ? 'Link copiat!' : 'Copiază link'}</span>
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
                <Button
                  onClick={() => setInviteUrl(null)}
                  variant="ghost"
                  size="sm"
                  className="h-8 text-red-500 hover:text-red-600"
                >
                  <FiX className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-xs text-gray-500 italic">* Linkul expiră în 24 de secunde și poate fi folosit o singură dată</p>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between mb-4">
          <h3 className="font-medium text-gray-900">Angajați actuali</h3>
        </div>
        {developers?.map((developer) => (
          <div key={developer.userId} className="flex w-full bg-white rounded-lg border border-gray-100 overflow-hidden">
            <div className="w-1 bg-green-500"></div>
            <div className="px-4 py-2 w-full">
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">{developer.user?.email}</span>
                  <span className={`text-lg font-bold ${userId === developer.userId ? 'text-primary' : ''}`}>
                    {developer.user?.userName}
                  </span>
                </div>
                <span className="text-xs text-green-500 font-medium px-2 py-1 bg-green-50 rounded-full">
                  <Time d={developer.user?.createdAt} format="DD/MM/YYYY" />
                </span>
              </div>
            </div>
            <div className="flex flex-col justify-between py-2 px-3">
              <Button variant="ghost" size="icon" className="hover:bg-muted/70">
                <FiEdit2 className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="icon" className="hover:bg-red-100 hover:text-red-500">
                <FiTrash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
