import { getProduct } from 'Utils'

export const otherUserFields = `{
  userId
  user {
    email
    userName
    avatarUrl
    createdAt
  }
  isInvitation
  roles
  isBlocked
  createdAt
  expiresAt
}`

export const accountUserFields = `{
  email
  userName
  avatarUrl
  phone
  createdAt
}`

export const accountAppFields = `{
  appUserId
  appId
  appName
  roles
  description
  isApproved
  createdAt
  updatedAt
  users ${otherUserFields}
}`

export const accountCompanyFields = `{
  companyUserId
  cif
  companyName
  city
  county
  createdAt
  updatedAt
  users ${otherUserFields}
}`

const userAppsFields = `{
  appUserId
  appId
  isApproved
  appName
  createdAt
  updatedAt
}`

const userCompaniesFields = `{
  companyUserId
  cif
  companyName
  city
  county
  createdAt
  updatedAt
}`

export const notificationFields = `{
  id
  type
  data
  isRead
  isDone
  createdAt
}`

const commonFields = `
  jwtId
  accountId
  userId
  roles
  lastLoginAt
  isBlocked
  user ${accountUserFields}
  accountSettings
  userNotifications ${notificationFields}
  invitation {
    userName
    email
    invitedAt
    isApproval
    app ${accountAppFields}
    company ${accountCompanyFields}
  }
`

export const defaultAccountFields = `{
  ${commonFields}
}`

export const adminToolAccountFields = `{
  ${commonFields}
  app ${accountAppFields}
}`

export const devAccountFields = `{
  ${commonFields}
  app ${accountAppFields}
  userApps ${userAppsFields}
}`

export const appAccountFields = `{
  ${commonFields}
  company ${accountCompanyFields}
  userCompanies ${userCompaniesFields}
}`

export const getAccountFields = () => {
  let accountFields = defaultAccountFields
  if (getProduct() === 'ADMINTOOL') accountFields = adminToolAccountFields
  if (getProduct() === 'DEVPLATFORM') accountFields = devAccountFields
  if (getProduct() === 'WEBAPP') accountFields = appAccountFields
  return accountFields
}

export const getAuthTokensFields = `{
  accessToken
  accessTokenExpiresAt
  refreshToken
  refreshTokenExpiresAt
  account ${getAccountFields()}
}`
