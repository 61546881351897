import { FiChevronRight, FiPlus, FiSearch } from 'react-icons/fi'
import { Avatar, AvatarFallback, AvatarImage, Input, Section } from 'Components'
import { Link } from 'react-router-dom'
import { getInitialsByName } from 'Utils'

const people = [
  {
    name: 'Leslie Alexander',
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Michael Foster',
    email: 'michael.foster@example.com',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Dries Vincent',
    email: 'dries.vincent@example.com',
    role: 'Business Relations',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: null
  },
  {
    name: 'Lindsay Walton',
    email: 'lindsay.walton@example.com',
    role: 'Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Courtney Henry',
    email: 'courtney.henry@example.com',
    role: 'Designer',
    imageUrl:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Tom Cook',
    email: 'tom.cook@example.com',
    role: 'Director of Product',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: null
  }
]

export const Clients = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold sm:tracking-tight">Clienti</h1>

      <form>
        <div className="relative">
          <FiSearch className="text-muted-foreground absolute left-2 top-2.5 h-4 w-4" />
          <Input type="search" placeholder="Search" className="pl-8" />
        </div>
      </form>

      <Section>
        <Link to="/clients/new" className="flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
          <FiPlus className="size-5" />
          <span className="grow">Add a client</span>
        </Link>
      </Section>

      <Section>
        <ul>
          {people.map((person, i) => (
            <Person key={i} person={person} />
          ))}
        </ul>
      </Section>
    </div>
  )
}

const Person = ({ person }) => {
  const { imageUrl, name } = person
  return (
    <li className="rounded-xl p-2 hover:bg-secondary-background">
      <Link to={`/clients/372`} className="flex items-center justify-between gap-x-6">
        <div className="flex min-w-0 gap-x-4">
          <Avatar>
            <AvatarImage src={imageUrl} alt="User avatar" />
            <AvatarFallback>{getInitialsByName(name)}</AvatarFallback>
          </Avatar>
          <div className="min-w-0 flex-auto">
            <p className="text-sm/6 font-semibold">{name}</p>
            <p className="flex text-xs/5 text-gray-500">dsa</p>
          </div>
        </div>
        <FiChevronRight aria-hidden="true" className="size-5 flex-none text-gray-400" />
      </Link>
    </li>
  )
}
