import { Section } from 'Components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { FiChevronRight, FiMail, FiUser } from 'react-icons/fi'

export const ProfileSettings = () => {
  const user = useSelector((state) => state.auth.account?.user)

  const items = [
    {
      label: 'Account',
      value: user.userName,
      icon: FiUser,
      to: '/settings/account/profile/details'
    },
    {
      label: 'Email',
      value: user.email,
      icon: FiMail,
      to: '/settings/account/profile/email'
    }
  ]

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Personal details</h3>

      <Section>
        <ul>
          {items.map(({ icon: Icon, to, label, value }, i) => (
            <li className="rounded-xl p-2 hover:bg-secondary-background">
              <Link key={i} to={to} className="flex items-center justify-between gap-x-6">
                <div className="flex min-w-0 gap-x-4">
                  <div className="flex size-10 items-center justify-center rounded-full bg-muted">
                    <Icon className="size-5" />
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm/6 font-semibold">{label}</p>
                    {value && <p className="flex text-xs/5 text-gray-500">{value}</p>}
                  </div>
                </div>
                <FiChevronRight aria-hidden="true" className="size-5 flex-none text-gray-400" />
              </Link>
            </li>
          ))}
        </ul>
      </Section>
    </>
  )
}
