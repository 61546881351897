import { Avatar, AvatarFallback, AvatarImage, Section } from 'Components'
import { Link } from 'react-router-dom'
import { getInitialsByName } from 'Utils'

const invoices = [
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' }
]

export function RecentInvoices() {
  return (
    <Section>
      <ul>
        {invoices?.map((invoice, i) => (
          <Invoice key={i} invoice={invoice} />
        ))}
      </ul>
    </Section>
  )
}

const Invoice = ({ invoice }) => {
  const { id, amount, avatarUrl, companyName } = invoice
  return (
    <li className="rounded-xl p-2 hover:bg-secondary-background">
      <Link to={`/invoices/${id}`} className="flex items-center justify-between gap-x-6">
        <div className="flex min-w-0 gap-x-4">
          <Avatar>
            <AvatarImage src={avatarUrl} alt="User avatar" />
            <AvatarFallback>{getInitialsByName(companyName)}</AvatarFallback>
          </Avatar>
          <div className="min-w-0 flex-auto">
            <p className="text-sm/6 font-semibold">{companyName}</p>
            <p className="flex text-xs/5 text-gray-500">dsa</p>
          </div>
        </div>
        <div className="flex-none text-gray-400">{amount}</div>
      </Link>
    </li>
  )
}
