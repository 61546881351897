import { Section } from 'Components'
import { FiBell, FiMail } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { route } from 'Utils'

const items = [
  {
    name: 'Push notifications',
    icon: FiBell,
    to: route('account.notifications.push')
  },
  {
    name: 'Email notifications',
    icon: FiMail,
    to: route('account.notifications.email')
  }
]

export const NotificationSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Notifications</h3>

      <Section>
        {items.map(({ icon: Icon, to, name }, i) => (
          <Link key={i} to={to} className="flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
            <Icon className="size-5" />
            <span className="grow">{name}</span>
          </Link>
        ))}
      </Section>
    </>
  )
}
