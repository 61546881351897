import { SUPPORTED_LOCALES } from 'Constants'
import { getLowerCaseIndexOfArray } from 'Utils'

// used by the translation T component
export const translate = ({ languageKeys, key, variables, noKey } = {}) => {
  languageKeys = languageKeys || {}
  if (!key) return ''
  if (languageKeys[key] !== undefined) {
    let def = languageKeys[key]
    if (variables !== undefined) {
      for (const i in variables) {
        def = def.replace(`%${i}%`, variables[i])
      }
    }
    return def
  }
  return noKey ? '' : key
}

const LOCALES = Object.keys(SUPPORTED_LOCALES).reduce((acc, name) => [...acc, ...SUPPORTED_LOCALES[name]], [])
export const goodLocale = (locale) => getLowerCaseIndexOfArray(LOCALES, locale)
