import { DateTime, Section, Skeleton } from 'Components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export const EmployeeDetails = () => {
  let { userId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const employees = useSelector((state) => state.auth.account?.company?.users)
  console.log('🚀 ~ EmployeeDetails ~ employees:', employees)

  const { user, roles: permissions, avatarUrl } = employees.find((employee) => employee.userId === userId)
  console.log('🚀 ~ EmployeeDetails ~ employees:', employees)

  return (
    <>
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{user.userName}</h3>
        <p className="max-w-2xl text-sm/6 text-gray-500">{user.email}</p>
      </div>

      <Section>
        <dl className="grid grid-cols-2 gap-4">
          <dt className="text-sm/6 font-medium">Date added</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">
            {/* {isLoading ? <Skeleton className="h-5 w-[150px]" /> : <DateTime d={createdAt} />} */}
          </dd>
        </dl>
      </Section>
    </>
  )
}
