import { useApp } from 'Hooks'
import { useEffect } from 'react'

export const SiteThemeProvider = ({ children }) => {
  const { theme, product } = useApp()
  const root = window.document.documentElement

  useEffect(() => {
    root.classList.remove('light', 'dark')

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
      root.classList.add(systemTheme)
      return
    }

    // Handle platform theme - make sure to use the correct class names
    const platformClasses = ['platform-webapp', 'platform-devplatform', 'platform-admintool']
    platformClasses.forEach((cls) => root.classList.remove(cls))

    if (product) {
      const platformClass = `platform-${product}`
      root.classList.add(platformClass)
    }

    root.classList.add(theme)
  }, [product, root.classList, theme])

  return children
}
