import { DateTime, Section, Skeleton } from 'Components'
import { useListItem } from 'Hooks'
import { useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Actions } from 'Store'

export const SessionDevice = () => {
  let { sessionId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const isActive = useSelector((state) => state.auth.account.jwtId === sessionId)
  const { deviceType, os, browser, ipAddress, createdAt, location, refreshedAt } = useListItem('userSessions', sessionId) || {}

  const logout = async () => {
    if (isActive) {
      await Actions.logout()
    } else {
      await Actions.logout(null, { jwtId: sessionId })
      navigate(-1)
    }
  }

  return (
    <div>
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{os}</h3>
        <p className="text-sm/6 text-gray-500">{isActive ? 'This Device' : <DateTime d={refreshedAt} />}</p>
      </div>
      <Section>
        <dl className="grid grid-cols-2 gap-4 p-2.5">
          <dt className="text-sm/6 font-medium">Browser</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">{browser}</dd>
          <dt className="text-sm/6 font-medium">Device</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">{deviceType}</dd>
          <dt className="text-sm/6 font-medium">Location</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">{location}</dd>
          <dt className="text-sm/6 font-medium">When</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">
            {isLoading ? <Skeleton className="h-5 w-[150px]" /> : <DateTime d={createdAt} />}
          </dd>
          <dt className="text-sm/6 font-medium">IP</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">
            {isLoading ? <Skeleton className="h-5 w-[150px]" /> : ipAddress}
          </dd>
        </dl>
      </Section>

      <Section>
        <button
          onClick={() => logout()}
          className="flex w-full items-center gap-x-4 rounded-xl px-3 py-4 text-destructive hover:bg-secondary-background"
        >
          <FiLogOut className="size-5" />
          <span className="grow text-left">Întrerupe sesiunea</span>
        </button>
      </Section>
    </div>
  )
}
