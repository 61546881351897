import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {}
})

export const reducerActions = slice.actions

export default slice.reducer
