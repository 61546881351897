import {
  AdminHome,
  AdminSettings,
  AdminUsersList,
  TailwindIndicator,
  AdminContactForm,
  AppLayout,
  NotAuthLayout,
  AdminProfile,
  AdminSettingsLayout,
  UserProfile,
  LoginPanel,
  Logout,
  MainModal,
  ApproveAppPanel,
  AdminAppsList,
  AdminStatus,
  AppSessions,
  AdminSessions,
  AdminHumanUsersList,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs
} from 'Components'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Actions } from 'Store'

export const AdminAppLayout = () => {
  const navigate = useNavigate()
  const app = useSelector((state) => state.auth.account.app)
  const invitation = useSelector((state) => state.auth.account.invitation)
  useEffect(() => {
    if (app && invitation && !invitation.isSeen) {
      Actions.setInvitationSeen()
      navigate('/modals/app')
    }
  }, [app, invitation, navigate])
  return <AppLayout />
}

export const AdminRouter = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/contact" element={<AdminContactForm />} />
          <Route path="/whoami" element={<WhoAmI />} />
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route element={<NotAuthLayout hasTermsAndConditions={false} />}>
          <Route path="login" element={<LoginPanel />} />
        </Route>
        <Route element={<AdminAppLayout />}>
          <Route path="/" element={<AdminHome />} />
          <Route path="/admin-users" element={<AdminUsersList />} />
          <Route path="/status" element={<AdminStatus />} />
          <Route path="/sessions" element={<AdminSessions />} />
          <Route path="/users" element={<AdminHumanUsersList />} />
          <Route path="/apps" element={<AdminAppsList />} />
          <Route path="/settings" element={<AdminSettingsLayout />}>
            <Route index element={<AdminSettings />} />
            <Route path="adminlogs" element={<EntityActivityLogs />} />
          </Route>
          <Route path="/profile" element={<AdminProfile />}>
            <Route index element={<UserProfile />} />
            <Route path="sessions" element={<AppSessions />} />
            <Route path="logs" element={<UserActivityLogs />} />
          </Route>
          <Route path="modals" element={<MainModal />}>
            <Route path="app" element={<ApproveAppPanel />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    <TailwindIndicator />
  </>
)
