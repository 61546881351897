import * as React from 'react'
import { cn } from 'Utils'

const findActiveIndex = (tempOtp) => {
  let index = tempOtp.findIndex((otp) => otp === '')
  if (index === -1) {
    if (tempOtp.join('')) index = tempOtp.length - 1
    else index = -1
  }
  return index
}

const PinCode = React.forwardRef(({ length, className, pinClassName, type, onChange, value, ...props }, ref) => {
  type = type || 'number'
  length = length || 4
  // form an array of chars with length equal to length and fill it with the char value from value prop and fill the rest with empty string
  const [tempOtp, setTempOtp] = React.useState(new Array(length).fill('').map((_, index) => value?.[index] || ''))

  const [activeOtpIndex, setActiveOtpIndex] = React.useState()
  const inputRef = React.useRef(null)

  function handlePaste(event) {
    let copiedValue = event.clipboardData.getData('text') || ''
    // take only the number chars from copied value
    copiedValue = copiedValue.match(/\d/g)?.join('') || ''
    copiedValue = copiedValue.split('')
    const newOtp = new Array(length).fill('').map((_, index) => copiedValue[index] || '')
    setTempOtp(newOtp)
    event.preventDefault()
  }

  const handleOnchange = (value, index) => {
    const old = tempOtp[index]
    value = (value || '').replace(old, '')
    let acIn = activeOtpIndex || 0
    if (acIn > index) acIn = index
    const newOtp = tempOtp.map((vl, ix) => (ix === acIn ? value : ix > index ? '' : vl))
    setTempOtp(newOtp)
  }

  const handleOnKeyDown = (key, index) => {
    if (key === 'Backspace') {
      const newOtp = tempOtp.map((vl, ix) => (ix < index - 1 ? vl : ''))
      if (newOtp.join('') === tempOtp.join(''))
        setActiveOtpIndex(findActiveIndex(tempOtp) < 1 ? (activeOtpIndex === 0 ? null : 0) : findActiveIndex(tempOtp) - 1)
      else setTempOtp(newOtp)
    }
    if (key === 'ArrowRight' && index < length - 1) {
      setActiveOtpIndex(index + 1)
    }

    if (key === 'ArrowLeft' && index > 0) {
      setActiveOtpIndex(index - 1)
    }
  }

  React.useEffect(() => {
    setTempOtp(new Array(length).fill('').map((_, index) => value?.[index] || ''))
  }, [value, length])

  React.useEffect(() => {
    setActiveOtpIndex(findActiveIndex(tempOtp))
    const val = tempOtp.join('')
    onChange({ target: { value: val } })
  }, [onChange, tempOtp])

  React.useEffect(() => {
    inputRef.current?.focus()
  }, [activeOtpIndex])

  return (
    <div ref={ref} className={cn('mb-6 flex w-full items-center justify-center gap-6', className)} {...props}>
      {tempOtp.map((_, index) => {
        return (
          <React.Fragment key={'7' + index}>
            <input
              ref={(index || 0) === (activeOtpIndex || 0) ? inputRef : null}
              onChange={(e) => handleOnchange(e.target?.value || '', index)}
              onKeyDown={(e) => handleOnKeyDown(e.key, index)}
              onPaste={(event) => handlePaste(event)}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              className={cn(
                'size-12 rounded border text-center [appearance:textfield] placeholder:text-slate-300 dark:placeholder:text-slate-500 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
                pinClassName
              )}
              type={type}
              inputMode={type === 'text' ? type : 'numeric'}
              placeholder={(index + 1).toString()}
              value={tempOtp[index]}
            />
            {/* {index === tempOtp.length - 1 ? null : <span className='w-2 bg-foreground py-[0.5px]' />} */}
          </React.Fragment>
        )
      })}
    </div>
  )
})
PinCode.displayName = 'PinCode'

export { PinCode }
