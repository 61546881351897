import {
  _callAPI,
  approveOrDenyAppQuery,
  deleteAdminUserQuery,
  getAdminActivityLogsQuery,
  getAdminAppsQuery,
  getAdminHumanUsersQuery,
  getAdminSessionsQuery,
  getAdminUsersQuery,
  saveAdminAppQuery,
  saveAdminUserQuery,
  sendContactFormQuery
} from 'API'

export const _getAdminUsers = async () => {
  const { error, getAdminUsers: result } = (await _callAPI({ query: getAdminUsersQuery })) || {}
  return error ? { error } : result || []
}

export const _getAdminHumanUsers = async () => {
  const { error, getAdminHumanUsers: result } = (await _callAPI({ query: getAdminHumanUsersQuery })) || {}
  return error ? { error } : result || []
}

export const _getAdminSessions = async () => {
  const { error, getAdminSessions: result } = (await _callAPI({ query: getAdminSessionsQuery })) || {}
  return error ? { error } : result || []
}

export const _getAdminActivityLogs = async () => {
  const { error, getAdminActivityLogs: result } = (await _callAPI({ query: getAdminActivityLogsQuery })) || {}
  return error ? { error } : result || []
}

export const _getAdminApps = async () => {
  const { error, getAdminApps: result } = (await _callAPI({ query: getAdminAppsQuery })) || {}
  return error ? { error } : result || []
}

export const _saveAdminUser = async ({ email, userName, roles, isBlocked }) => {
  const { error, saveAdminUser: result } =
    (await _callAPI({
      query: saveAdminUserQuery,
      variables: {
        email,
        userName,
        roles,
        isBlocked
      }
    })) || {}
  return error ? { error } : result || {}
}

export const _deleteAdminUser = async (email) => {
  const { error, deleteAdminUser: result } = (await _callAPI({ query: deleteAdminUserQuery, variables: { email } })) || {}
  return error ? { error } : result || false
}

export const _sendContactForm = async (contactData, captchaToken) => {
  const { error } =
    (await _callAPI({ query: sendContactFormQuery, variables: { contactData, captchaToken }, isPublic: true })) || {}
  return error ? { error } : true
}

export const _saveAdminApp = async ({ appUserId, isApproved } = {}) => {
  if (!appUserId) return
  const { error, saveAdminApp: result } =
    (await _callAPI({ query: saveAdminAppQuery, variables: { appData: { appUserId, isApproved } } })) || {}
  return error ? { error } : result || false
}

export const _approveOrDenyApp = async ({ appId, isApproved } = {}) => {
  if (!appId) return
  const { approveOrDenyApp: result } = (await _callAPI({ query: approveOrDenyAppQuery, variables: { appId, isApproved } })) || {}
  return result || false
}
