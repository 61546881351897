import {
  LocalesProvider,
  StoreProvider,
  DebugProvider,
  AuthProvider,
  SiteThemeProvider,
  ToastContainer,
  MainToastProvider,
  PusherProvider
} from 'Components'

// the root app exported by the index which will apply the providers
export const RootApp = ({ children }) => {
  return (
    <StoreProvider>
      <DebugProvider>
        <SiteThemeProvider>
          <LocalesProvider>
            <AuthProvider>
              <PusherProvider>
                <MainToastProvider>
                  <ToastContainer />
                  {children}
                </MainToastProvider>
              </PusherProvider>
            </AuthProvider>
          </LocalesProvider>
        </SiteThemeProvider>
      </DebugProvider>
    </StoreProvider>
  )
}
