import { State } from 'Store'
import { translate } from 'Utils'

export const getTimezone = () => {
  return State().locales.timezone
}

// the translate as static text, when cannot be used as a hook
export const getTransaltion = (textObj) => {
  textObj = textObj || ''
  const languageKeys = State().locales.languageKeys
  const { k, ...variables } = typeof textObj === 'string' ? { k: textObj } : textObj

  // translate the key
  const translation = translate({ languageKeys, key: k, variables })
  return translation
}
