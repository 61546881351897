import { getAuthTokensFields } from 'API'

export const registerAppQuery = `
  mutation registerApp($appData: AppPayload) {
    registerApp(appData: $appData) ${getAuthTokensFields}
  }`

export const selectAppQuery = `
  mutation selectApp($appUserId: ID!) {
    selectApp(appUserId: $appUserId) ${getAuthTokensFields}
  }
`

export const inviteDeveloperQuery = `
  mutation inviteDeveloper {
    inviteDeveloper
  }
`
