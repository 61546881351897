import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { Button, CompanyInvitationCard, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { FiRefreshCw } from 'react-icons/fi'
import { debounce } from 'lodash'

export const validationSchema = z.object({
  companyName: z
    .string()
    .min(3, 'Numele trebuie să conțină minim 3 caractere')
    .max(45, 'Numele trebuie să conțină maxim 45 caractere'),
  cif: z.string().min(3, 'CIF trebuie să conțină minim 3 caractere').max(10, 'CIF trebuie să conțină maxim 10 caractere'),
  city: z.string().optional(),
  county: z.string().optional(),
  serverError: z.string().optional()
})

export const CompanyNotApprovedScreen = () => {
  return <div>CompanyNotApprovedScreen</div>
}

// the executeRecaptcha is passed from the parent component
export const AcceptCompanyForm = ({ onCloseForm }) => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const invitation = useSelector((state) => state.auth.account.invitation)
  const form = useForm()

  const onSubmit = useCallback(async () => {
    await Actions.acceptInvitation()
    if (!errorAuth) onCloseForm?.()
  }, [errorAuth, onCloseForm])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <CompanyInvitationCard invitation={invitation} />
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        {errorAuth && <p className="mt-3 text-sm text-red-500">{errorAuth?.message}</p>}
        <div className="mt-4 flex items-center justify-between">
          <Button type="submit" isLoading={isLoadingAuth} className="mr-2 flex-1">
            Acceptați invitația
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const RegisterCompanyForm = ({ onCloseForm }) => {
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [showManualInput, setShowManualInput] = useState(false)
  const searchRef = useRef(null)

  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)

  const defaultValues = useMemo(
    () => ({
      searchTerm: '',
      companyName: '',
      cif: '',
      city: '',
      county: '',
      serverError: errorAuth?.message
    }),
    [errorAuth]
  )

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const { setValue, setFocus } = form

  useEffect(() => {
    // Set focus on the search input when the component mounts
    if (!showManualInput) {
      setFocus('searchTerm')
    } else {
      setFocus('cif')
    }
  }, [setFocus, showManualInput])

  const performSearch = useCallback(async (searchText) => {
    if (searchText.length < 5) {
      setSearchResults([])
      setIsSearching(false)
      return
    }

    setIsSearching(true)
    try {
      const companies = await Actions.searchRomanianCompanies(searchText)
      setSearchResults(companies)
    } catch (error) {
      console.error('Error searching companies:', error)
      setSearchResults([])
    } finally {
      setIsSearching(false)
    }
  }, [])

  const debouncedSearch = useMemo(() => debounce(performSearch, 1000), [performSearch])

  const handleSearch = useCallback(
    (searchText) => {
      if (searchRef.current) {
        clearTimeout(searchRef.current)
      }
      searchRef.current = setTimeout(() => {
        debouncedSearch(searchText)
      }, 1000)
    },
    [debouncedSearch]
  )

  useEffect(() => {
    return () => {
      if (searchRef.current) {
        clearTimeout(searchRef.current)
      }
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const [selectedCompany, setSelectedCompany] = useState(null)

  const handleCompanySelect = (company) => {
    setValue('cif', company.cif)
    setValue('companyName', company.name)
    setValue('city', company.city)
    setValue('county', company.county)
    setSearchResults([])
    setSelectedCompany(company)
    setShowManualInput(true)
  }

  const handleBackToSearch = () => {
    setShowManualInput(false)
    setValue('cif', '')
    setValue('companyName', '')
    setValue('city', '')
    setValue('county', '')
    setSelectedCompany(null)
    setFocus('searchTerm')
    Actions.setErrorAuth()
  }

  const onSubmit = useCallback(
    async ({ companyName, cif, city, county }) => {
      if (companyName && cif) {
        await Actions.registerCompany({ companyName, cif, city, county, country: 'RO' })
        if (!errorAuth) onCloseForm?.()
      }
    },
    [errorAuth, onCloseForm]
  )

  return (
    <Form {...form}>
      <h1 className="text-2xl font-semibold tracking-tight">Date firma pentru care se va crea contul</h1>
      <p className="text-muted-foreground text-sm">
        Recomandam sa introduci date reale, altfel nu vei putea accesa toate funcțiile. Poti cauta dupa codul fiscal fara RO sau
        dupa denumirea companiei.
      </p>

      {!showManualInput && (
        <div className="relative">
          <Input
            {...form.register('searchTerm')}
            placeholder="Introdu codul fiscal sau denumirea"
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full rounded-lg p-3"
            disabled={isSearching}
          />

          {isSearching && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
              <FiRefreshCw className="h-5 w-5 animate-spin text-gray-400" />
            </div>
          )}

          {searchResults.length > 0 && (
            <div className="absolute z-10 mt-1 max-h-96 w-full overflow-y-auto rounded-lg bg-white shadow-lg">
              {searchResults.map((company) => (
                <button
                  key={company.cif}
                  className="w-full border-b p-3 text-left last:border-b-0 hover:bg-gray-100"
                  onClick={() => handleCompanySelect(company)}
                >
                  <div className="font-medium">{company.name}</div>
                  <div className="text-sm text-gray-600">
                    {company.cif}, {company.city}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      )}

      <p className="mt-2 text-sm text-gray-600">
        {showManualInput ? (
          <button className="text-blue-600 hover:underline" onClick={handleBackToSearch}>
            ← Înapoi la căutare
          </button>
        ) : (
          <button className="text-blue-600 hover:underline" onClick={() => setShowManualInput(true)}>
            Nu îți găsești firma în listă? Utilizează mai multe caractere.
          </button>
        )}
      </p>

      {showManualInput && (
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          {!selectedCompany && (
            <>
              <FormField
                control={form.control}
                name="cif"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CIF</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="companyName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Numele companiei</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Oraș</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="county"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Județ</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          {selectedCompany && (
            <div className="rounded-lg bg-gray-100 p-4 shadow-sm">
              <div className="flex items-center">
                <div className="w-1/5 font-semibold">{selectedCompany.cif}</div>
                <div className="w-3/5 truncate">{selectedCompany.name}</div>
                <div className="w-1/5 text-right text-sm text-gray-600">
                  {selectedCompany.city}, {selectedCompany.county}
                </div>
              </div>
            </div>
          )}

          {errorAuth && <p className="text-sm text-red-500">{errorAuth?.message}</p>}
          <div className="mt-4 flex items-center justify-between">
            <Button type="submit" isLoading={isLoadingAuth} className="mr-2 flex-1">
              {selectedCompany ? 'Confirmă și Continuă' : 'Continuă'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
