import { useSelector } from 'react-redux'
import { translate, dangerousHTML, sanitizeHTML, moment } from 'Utils'
import { memoize } from 'proxy-memoize'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'Constants'

// translates text in the selected language
export const T = (textObj) => {
  textObj = textObj || ''
  const languageKeys = useSelector(memoize((state) => state.locales.languageKeys))
  // will have also a shorthand for textObj = 'key'
  const { k, isDangerous, noKey, children: text, ...variables } = typeof textObj === 'string' ? { k: textObj } : textObj

  // take the defin_itions from the store based on the language selected

  // translate the key
  const translation = translate({ languageKeys, key: k, variables, noKey })

  // for the dangerous mode we will return the html object
  if (isDangerous) return dangerousHTML(translation)
  return translation
}

// same function as dangerousHTML
export const Td = (textObj) => {
  textObj = textObj || ''
  const t = T(textObj)
  const td = T({ ...textObj, isDangerous: true })
  return typeof textObj === 'string' ? sanitizeHTML(t) : td
}

// component for displaying prices
export const Price = ({ p }) => {
  const locale = useSelector((state) => state.locales.locale) || DEFAULT_LOCALE
  const currencyCode = useSelector((state) => state.app.currencyCode) || DEFAULT_LOCALE
  p = p || 0
  const options = {
    style: 'currency',
    currency: currencyCode || DEFAULT_CURRENCY,
    currencyDisplay: 'symbol'
  }
  return new Intl.NumberFormat(locale, options).format(p)
}

// component for displaying dates
export const Time = (dateObj, format2) => {
  const timezone = useSelector((state) => state.locales.timezone)
  let d,
    format = format2,
    tz,
    ts
  if (typeof dateObj === 'string') d = dateObj
  else if (typeof dateObj === 'number') ts = dateObj
  else {
    ;({ d, format, tz, ts } = dateObj)
  }
  if (!ts && !d) return ''
  d = ts ? new Date(Number(ts)) : d || new Date()
  format = format || 'DD MMM YYYY, HH:mm'
  tz = tz || timezone
  return moment(d).tz(tz).format(format)
}

export const Day = (obj) => {
  return Time(obj?.d || obj, 'YYYY-MM-DD')
}

export const DateTime = (obj) => {
  return Time(obj?.d || obj, 'DD/MM/YYYY HH:mm')
}
