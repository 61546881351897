import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'Constants'
import { getLanguageFromLocale, getLocalStorageKey, getQueryParamsKey, goodLocale, goodTimezone, moment } from 'Utils'

const localesStorage = getLocalStorageKey('locales')
// the init timezone is from the route or env or default
const INIT_TIMEZONE =
  goodTimezone(getQueryParamsKey('tz')) ||
  goodTimezone(process.env.REACT_APP_TIMEZONE) ||
  goodTimezone(localesStorage.timezone) ||
  goodTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone) ||
  DEFAULT_TIMEZONE

// the init locale is from the route or env or default
const INIT_LOCALE =
  goodLocale(getQueryParamsKey('locale')) ||
  goodLocale(process.env.REACT_APP_LOCALE) ||
  goodLocale(localesStorage.locale) ||
  DEFAULT_LOCALE

const INIT_LANGUAGE = getLanguageFromLocale(INIT_LOCALE)

const initialState = {
  // the locale as de-ch
  locale: INIT_LOCALE,

  // the language as de
  language: INIT_LANGUAGE,

  // the hash with translations for this language
  languageKeys: {},

  // if language is loading
  isLoadingLocale: true,

  // the selected timezone
  timezone: INIT_TIMEZONE
}

const reducers = {
  _changeLocale: (state, action) => {
    state.locale = action.payload
    state.language = getLanguageFromLocale(state.locale)
    moment.locale(state.language)
    state.isLoadingLocale = true
  },

  _setLanguageKeys: (state, action) => {
    state.languageKeys = action.payload
    state.isLoadingLocale = false
  },

  _setTimezone: (state, action) => {
    state.timezone = action.payload
  }
}

const slice = createSlice({
  name: 'locales',
  initialState,
  reducers
})

export const reducerActions = slice.actions
export default slice.reducer
