import React, { useEffect, useState } from 'react'
import { FiChrome, FiLogOut } from 'react-icons/fi'
import { Section, Skeleton, Time } from 'Components'
import { useSelector } from 'react-redux'
import API from 'API'
import { Actions } from 'Store'
import { useList, useListItem } from 'Hooks'
import { Link } from 'react-router-dom'
import { route } from 'Utils'

const SessionItem = ({ id: sessionId, isActive }) => {
  const { deviceType, os, location, refreshedAt } = useListItem('userSessions', sessionId) || {}
  return (
    <Link
      to={route('account.sessions.session', { sessionId })}
      className="flex items-center gap-x-4 rounded-xl px-2 py-1 hover:bg-secondary-background"
    >
      <div className="flex size-12 items-center justify-center rounded-full bg-muted">
        <FiChrome className="size-6" />
      </div>
      <div>
        <p className="text-sm/6 font-semibold">{os}</p>
        <p className="truncate text-xs/5">{isActive ? 'This device' : <Time format="DD/MM/YYYY HH:mm" d={refreshedAt} />}</p>
      </div>
    </Link>
  )
}

const SessionItemSkeleton = () => {
  return (
    <div className="flex items-center gap-x-4 rounded-xl px-2 py-1">
      <Skeleton className="size-12 rounded-full" />
      <div>
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="mt-1 h-4 w-[200px]" />
      </div>
    </div>
  )
}

export const AppSessions = () => {
  const sessions = useList('userSessions', 'refreshedAt', true)
  console.log('🚀 ~ AppSessions ~ sessions:', sessions)
  const [isLoading, setIsLoading] = useState(!sessions.length)

  const activeId = useSelector((state) => state.auth.account.jwtId)

  //
  sessions.splice(
    sessions.findIndex((session) => activeId === session.jwtId),
    1
  )

  useEffect(() => {
    const syncList = async () => {
      const userSessions = await API._getUserSessions()
      if (userSessions?.error) return
      Actions.populateUserSessions(userSessions)
      setIsLoading(false)
    }
    syncList()
  }, [])

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold sm:tracking-tight">Sesiuni active</h1>
        <Section title="Sesiune curentă">{isLoading ? <SessionItemSkeleton /> : <SessionItem id={activeId} isActive />}</Section>

        <Section title="Active Sessions">
          {isLoading && [...Array(3)].map(() => <SessionItemSkeleton />)}
          {!isLoading && sessions.map((session, i) => <SessionItem key={i} id={session.jwtId} />)}
        </Section>
      </div>

      {!!sessions.length && (
        <Section className="fixed bottom-0 md:sticky">
          <button
            onClick={() => Actions.logout(null, { allOtherSessions: true })}
            className="flex w-full items-center gap-x-4 rounded-xl px-3 py-4 text-destructive hover:bg-secondary-background"
          >
            <FiLogOut className="size-5" />
            <span className="grow text-left">Întrerupe celelalte sesiuni</span>
          </button>
        </Section>
      )}
    </>
  )
}
