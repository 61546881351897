import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const validationSchema = z.object({
  userName: z
    .string()
    .min(3, 'Numele trebuie să conține minim 3 caractere')
    .max(45, 'Numele trebuie să conține maxim 45 caractere')
})

export const UserProfileForm = () => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)

  const defaultValues = useMemo(() => ({ userName: '' }), [])

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const { setFocus } = form

  const onSubmit = useCallback(async ({ userName }) => {
    await Actions.saveUserProfile({ userName })
  }, [])

  useEffect(() => {
    setFocus('userName')
  }, [setFocus])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Ne trebuie câteva detalii despre tine</h1>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        <FormField
          control={form.control}
          name="userName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Numele cu care o sa te vezi pe platforma noastra</FormLabel>
              <FormControl>
                <Input {...field} className="rounded-lg" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {errorAuth && <div className="rounded-lg bg-destructive/10 p-3 text-sm text-destructive">{errorAuth?.message}</div>}
        <div className="mt-4 flex items-center justify-between">
          <Button type="submit" isLoading={isLoadingAuth} className="mr-2 flex-1">
            Salveaza datele
          </Button>
        </div>
      </form>
    </Form>
  )
}
