// emoji regex detection in strings to be used
export const EMOJIS_REGEX =
  '[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]'

// dictionary of html tags to be used in the sanitization of html strings
export const HTML_TAG_DICT = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '/': '&#x2F;'
}

// checks if the local storage is supported
export const HAS_LOCAL_STORAGE = (() => {
  try {
    const testKey = '__testKey__'
    localStorage.setItem(testKey, testKey)
    const item = localStorage.getItem(testKey)
    return item === testKey
  } catch {
    return false
  }
})()

export const IS_DEBUG = ['development', 'staging'].includes(process.env.REACT_APP_OWN_ENV)
