import { useLocation, Outlet, useNavigate } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle
} from 'Components'
import { Actions } from 'Store'
import { useIsMobile } from 'Hooks'

export const MainModal = () => {
  const isMobile = useIsMobile()

  const location = useLocation()
  const navigate = useNavigate()
  const [, , part2] = location.pathname.split('/')
  const { title, description } = location.state || {}

  const onCloseModal = () => {
    if (part2 && Actions[`${part2}Close`]) Actions[`${part2}Close`]()
    navigate(-1)
  }

  if (isMobile)
    return (
      <Drawer open onOpenChange={(open) => !open && onCloseModal()}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription>{description}</DrawerDescription>
          </DrawerHeader>
          <div className='m-4'>
            <Outlet />
          </div>
        </DrawerContent>
      </Drawer>
    )

  return (
    <Dialog open onOpenChange={(open) => !open && onCloseModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <Outlet />
      </DialogContent>
    </Dialog>
  )
}
