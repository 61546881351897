import { Separator } from "Components"

export const CompanySettings = () => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Company settings</h3>
        <p className="text-muted-foreground text-sm">
          Customize the appearance of the app. Automatically switch between day and night themes.
        </p>
      </div>
      <Separator />
    </div>
  )
}
