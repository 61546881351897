import { useSelector } from 'react-redux'
import { Avatar, AvatarFallback, AvatarImage, Section } from 'Components'

import { Link } from 'react-router-dom'
import { getInitialsByName, route } from 'Utils'
import { FiChevronRight, FiPlus } from 'react-icons/fi'

const Company = ({ company }) => {
  const { companyUserId, companyName, county, city } = company
  return (
    <li className="rounded-xl p-2 hover:bg-secondary-background">
      <Link
        to={route('account.companies.company', { companyId: companyUserId })}
        className="flex items-center justify-between gap-x-6"
      >
        <div className="flex min-w-0 gap-x-4">
          <Avatar>
            <AvatarImage src={company.avatarUrl} alt="User avatar" />
            <AvatarFallback>{getInitialsByName(companyName)}</AvatarFallback>
          </Avatar>
          <div className="min-w-0 flex-auto">
            <p className="text-sm/6 font-semibold">{companyName}</p>
            <p className="flex text-xs/5 text-gray-500">
              {city}, {county}
            </p>
          </div>
        </div>
        <FiChevronRight aria-hidden="true" className="size-5 flex-none text-gray-400" />
      </Link>
    </li>
  )
}

export const AppCompanies = () => {
  const companies = useSelector((state) => state.auth.account?.userCompanies)
  console.log('🚀 ~ AppCompanies ~ companies:', companies)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Firmele mele</h3>

      <Section>
        <Link to="/modals/company" className="flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
          <FiPlus className="size-5" />
          <span className="grow">Adaugă o altă firmă</span>
        </Link>
      </Section>

      <Section>
        <ul>
          {companies?.map((company, i) => (
            <Company key={i} company={company} />
          ))}
        </ul>
      </Section>
    </>
  )
}
