// helper reducer to populate a list
export const getListHash = (listArray, idKey) =>
  (listArray || []).reduce((acc, item) => ({ ...acc, [item[idKey || 'id']]: item }), {})

// retrive prop with dot nested in object recursively
export const getProp = (obj, prop) => {
  obj = obj || {}
  if (prop && typeof prop === 'string') {
    return prop.split('.').reduce((o, p) => o[p], obj)
  }
}
