import {
  FiHome,
  FiInbox,
  FiSend,
  FiPackage,
  FiArchive,
  FiShoppingBag,
  FiCode,
  FiActivity,
  FiClock,
  FiUsers,
  FiMessageSquare,
  FiGrid,
  FiBell,
  FiSettings,
  FiUser,
  FiLogOut,
  FiTool
} from 'react-icons/fi'

export const APP_SETTINGS_ITEMS = [
  {
    items: [
      {
        name: 'Account',
        icon: FiUser,
        to: 'account'
      },
      {
        name: 'Notifications',
        icon: FiBell,
        to: 'account.notifications'
      },
      {
        name: 'Preferences',
        icon: FiTool,
        to: 'account.preferences'
      }
    ]
  },
  {
    category: 'Company',
    items: [
      {
        name: 'Setări generale',
        icon: FiShoppingBag,
        to: 'settings.company'
      },
      {
        name: 'Autorizare SPV',
        icon: FiShoppingBag,
        to: 'settings.company.spv'
      },
      {
        name: 'Billing',
        icon: FiBell,
        to: 'settings.company.billing'
      },
      {
        name: 'Echipă',
        icon: FiUsers,
        to: 'settings.company.team'
      },
      {
        name: 'Activitate firmă',
        icon: FiShoppingBag,
        to: 'settings.company.logs'
      },
      {
        name: 'Apps',
        icon: FiGrid,
        to: 'settings.company.apps'
      }
    ]
  },
  {
    mobileOnly: true,
    items: [
      {
        name: 'Logout',
        icon: FiLogOut,
        to: 'logout'
      }
    ]
  }
]

export const APP_SIDEBAR_ITEMS = [
  {
    title: 'Achiziții',
    items: [
      {
        title: 'Facturi primite',
        to: 'expenses',
        icon: FiInbox,
        count: 24,
        actions: []
      },
      { title: 'Furnizori', to: 'suppliers', icon: FiShoppingBag },
      { title: 'NIR-uri', to: '/nir', icon: FiPackage }
    ]
  },
  {
    title: 'Vânzări',
    items: [
      {
        title: 'Facturi emise',
        to: 'invoices',
        icon: FiSend,
        actions: []
      },
      { title: 'Clienti', to: 'clients', icon: FiUsers },
      { title: 'Articole/Servicii', to: 'products', icon: FiArchive }
    ]
  },
  {
    title: 'Apps',
    items: [{ title: 'Whatsapp', to: 'apps.whatsapp', icon: FiMessageSquare }]
  }
]

export const DEV_SIDEBAR_ITEMS = [
  {
    title: '',
    hidden: false,
    items: [{ id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome }]
  },
  {
    id: 'apps',
    title: 'Applicatii',
    hidden: true,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiInbox }]
  }
]

export const ADMIN_SIDEBAR_ITEMS = [
  {
    id: 'dashboard',
    title: 'Panou de bord',
    hidden: false,
    items: [
      { id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome },
      { id: 'status', title: 'Status', to: '/status', icon: FiActivity },
      { id: 'sessions', title: 'Sesiuni', to: '/sessions', icon: FiClock },
      { id: 'users', title: 'Utilizatori', to: '/users', icon: FiUsers }
    ]
  },
  {
    id: 'managers',
    title: 'Manageri',
    hidden: false,
    items: [{ id: 'admusr', title: 'Lista useri', to: '/admin-users', icon: FiInbox }]
  },
  {
    id: 'apps',
    title: 'Aplicatii',
    hidden: false,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiCode }]
  }
]

export const APP_MOBILE_ITEMS = [
  { title: 'Dashboard', to: 'home', icon: FiHome },
  { title: 'Clienti', to: 'clients', icon: FiUsers },
  { title: 'Facturi emise', to: 'invoices', icon: FiSend },
  { title: 'Notifications', to: 'notifications', icon: FiBell },
  { title: 'Settings', to: 'settings', icon: FiSettings }
]

export const DEV_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/', icon: FiHome },
  { id: 'apps', title: 'Applicatii', to: '/apps', icon: FiInbox }
]

export const ADMIN_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/', icon: FiHome },
  { id: 'managers', title: 'Manageri', to: '/admin-users', icon: FiInbox }
]
