import { Section } from 'Components'

export const LoginProviders = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Sign-in options</h3>
      <Section></Section>
    </>
  )
}
