import { Link } from 'react-router-dom'

export const TermsAndConditions = () => (
  <p className="text-muted-foreground px-8 text-center text-sm sm:px-16">
    Continuând, sunteți de acord cu{' '}
    <Link to="/terms" className="underline underline-offset-4 hover:text-primary">
      Termenii și condițiile
    </Link>{' '}
    și{' '}
    <Link to="/privacy" className="underline underline-offset-4 hover:text-primary">
      Politica de confidențialitate
    </Link>
    .
  </p>
)
