export const initialLists = {
  adminUsers: {},
  adminActivityLogs: {},
  adminApps: {},
  adminHumanUsers: {},
  adminSessions: {},
  userSessions: {},
  userActivityLogs: {},
  entityActivityLogs: {}
}
