import { _callAPI, inviteDeveloperQuery, registerAppQuery, selectAppQuery } from 'API'

export const _registerApp = async (appData) => {
  const { error, registerApp: result } =
    (await _callAPI({
      query: registerAppQuery,
      variables: { appData },
      allowPublic: true
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _selectApp = async (appUserId) => {
  if (!appUserId) return null
  const { error, selectApp: result } =
    (await _callAPI({
      query: selectAppQuery,
      variables: { appUserId }
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _inviteDeveloper = async () => {
  const { inviteDeveloper: result } = (await _callAPI({ query: inviteDeveloperQuery })) || {}
  return result || null
}
