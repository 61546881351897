import React from 'react'
import { Badge } from 'Components'
import { FiCheck } from 'react-icons/fi'
import { cn } from 'Utils'

const MultiSelect = React.forwardRef(({ value = [], onChange, options = [] }, ref) => {
  const toggleRole = (role) => {
    const newValue = value.includes(role) ? value.filter((r) => r !== role) : [...value, role]
    onChange(newValue)
  }

  return (
    <div ref={ref} className="flex flex-wrap gap-2">
      {options.map((role) => (
        <Badge
          key={role}
          variant={value.includes(role) ? 'secondary' : 'outline'}
          className={cn('cursor-pointer', value.includes(role) && 'bg-primary text-primary-foreground')}
          onClick={() => toggleRole(role)}
        >
          {role}
          {value.includes(role) && <FiCheck className="ml-1 h-3 w-3" />}
        </Badge>
      ))}
    </div>
  )
})

MultiSelect.displayName = 'MultiSelect'

export { MultiSelect }
