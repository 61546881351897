import API from 'API'
import { Actions, ReducerActions, State } from 'Store'
import { getProduct } from 'Utils'

// will refresh the me account, or log out if session expired
export const getMe = async ({ onlyNotifications } = {}) => {
  const isOverwriteAccount = !onlyNotifications
  // only if logged in i need to refresh the me account
  const hasAccessToken = !!State().auth.accessToken

  if (hasAccessToken) {
    Actions.setLoadingAuth(true)
    const newAccount = await API._getMe({ onlyNotifications })
    if (newAccount && isOverwriteAccount) ReducerActions._setAccount(newAccount)
    else if (newAccount) ReducerActions._mergeAccount(newAccount)
  }
  Actions.setLoadingAuth(false)
}

// will merge the account with the new data
export const mergeAccount = (account) => {
  ReducerActions._mergeAccount(account)
}

// will set the account
export const setAccount = (account) => {
  ReducerActions._setAccount(account)
}

// will reset the auth state, calling the API only if not already invoked from calling the API methods
export const logout = async (skipAPICall, { allOtherSessions, jwtId } = {}) => {
  if (allOtherSessions || jwtId) {
    await API._logout({ allOtherSessions, jwtId })
    return
  }
  Actions.setIsLoadingApp(true)
  // will calle the API only if not already invoked from calling the API methods
  if (!skipAPICall) await API._logout()
  Actions.setIsLoadingApp(false)
  Actions.setAuth()
  Actions.resetLists()
}

// will set new auth state
export const setAuth = (auth) => {
  ReducerActions._setAuth(auth)
}

// will refresh the auth state
export const refreshAuth = (auth) => {
  ReducerActions._refreshAuth(auth)
}

export const setErrorAuth = (error) => {
  ReducerActions._setErrorAuth(error)
}

export const setLoadingAuth = (isLoading) => {
  ReducerActions._setLoadingAuth(isLoading)
}

export const onNewTokensArrived = (authTokens) => {
  const isAuth = !!State().auth.account.accountId
  // in case of error, it will be set in the state, otherwise the auth tokens will be set
  // if null will stay logged out
  if (isAuth) {
    // if nothing or error, display the error toast, but do not logout the user
    if (authTokens?.error || !authTokens) {
      Actions.showToast({
        message: authTokens?.error?.message || 'Tokenul de autentificare este invalid',
        variant: 'destructive'
      })
      Actions.setLoadingAuth(false)
    } else Actions.setAuth(authTokens)
  } else {
    // if error, set it in the state, otherwise set the auth tokens or clear the auth state
    if (authTokens?.error) {
      Actions.setErrorAuth(authTokens.error)
    } else Actions.setAuth(authTokens)
  }
}

// will go to the API to authenticate the user based on the access token
export const authenticateWithToken = async (token) => {
  if (!token) return
  // whatever the existing session, it will be logged out
  const authTokens = await API._authenticateWithToken(token)
  Actions.onNewTokensArrived(authTokens)
}

// will send an _email with an auth code
export const sendEmailAuthCode = async ({ email, captchaToken } = {}) => {
  if (!email) return
  // send the code with loader
  Actions.setLoadingAuth(true)
  return API._sendEmailAuthCode({ email, captchaToken })
}

// will make the actual login with the _email and code
export const loginWithEmailCode = async ({ email, pin, captchaToken }) => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._loginWithEmailCode({ email, pin, captchaToken })
  Actions.onNewTokensArrived(authTokens)
}

// saves the user profile
export const saveUserProfile = async (userData) => {
  // Actions.setLoadingAuth(true)
  const authTokens = await API._saveUserProfile(userData)
  Actions.onNewTokensArrived(authTokens)
  return authTokens.account.user
}

export const handleProviderLogin = async (provider) => {
  const accessToken = State().auth.accessToken
  Actions.setLoadingAuth(true)
  let headers = { 'x-product': getProduct(), 'x-sd-allow': true }
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`
  // Get OAuth URL from backend
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/${provider}/url`, { headers }).catch(() => {})
  // Check the content type of the response
  const contentType = response?.headers?.get('content-type')
  let error = { code: 'OAUTH_ERROR', message: 'Nu s-a putut obține URL-ul de autentificare' }
  const url = contentType?.includes('application/json') ? (await response?.json())?.url : await response?.text()
  if (url && url.includes('http')) window.location.href = url
  else Actions.setErrorAuth(error)
}

export const acceptInvitation = async () => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._acceptInvitation()
  Actions.onNewTokensArrived(authTokens)
}

export const denyInvitation = async () => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._denyInvitation()
  Actions.onNewTokensArrived(authTokens)
}

export const populateUserSessions = (userSessions) => {
  ReducerActions._populateList({ list: 'userSessions', items: userSessions, idKey: 'jwtId' })
}

export const populateUserActivityLogs = (userActivityLogs) => {
  ReducerActions._populateList({ list: 'userActivityLogs', items: userActivityLogs, idKey: 'id' })
}

export const populateEntityActivityLogs = (entityActivityLogs) => {
  ReducerActions._populateList({ list: 'entityActivityLogs', items: entityActivityLogs, idKey: 'id' })
}

export const readNotifications = async (ids) => {
  await API._readNotifications(ids)
  return true
}

export const deleteNotifications = async (ids) => {
  await API._deleteNotifications(ids)
  return true
}
