import { Section } from 'Components'
import { FiClock, FiGlobe, FiSun } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { route } from 'Utils'

const items = [
  {
    name: 'Language',
    icon: FiGlobe,
    to: route('account.preferences.language')
  },
  {
    name: 'Theme',
    icon: FiSun,
    to: route('account.preferences.theme')
  },
  {
    name: 'Timezone',
    icon: FiClock,
    to: route('account.preferences.timezone')
  }
]

export const PreferencesSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Preferences</h3>
      <Section>
        {items.map(({ icon: Icon, to, name }, i) => (
          <Link key={i} to={to} className="flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background">
            <Icon className="size-5" />
            <span className="grow">{name}</span>
          </Link>
        ))}
      </Section>
    </>
  )
}
