import { ProfileHeader, Section } from 'Components'
import { FiBriefcase, FiKey, FiList, FiMonitor, FiTrash2, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { cn, route } from 'Utils'

const items = [
  {
    items: [
      {
        name: 'Personal details',
        icon: FiUser,
        to: '/settings/account/profile'
      },
      {
        name: 'Companies',
        icon: FiBriefcase,
        to: route('account.companies')
      },
      {
        name: 'Sesiuni active',
        icon: FiMonitor,
        to: route('account.sessions')
      },
      {
        name: 'Sign-in options',
        icon: FiKey,
        to: '/settings/account/logins'
      },
      {
        name: 'Activitate',
        icon: FiList,
        to: '/settings/account/activity'
      }
    ]
  },
  {
    items: [
      {
        name: 'Dezactivează contul',
        icon: FiTrash2,
        to: route('account.delete'),
        className: 'text-destructive'
      }
    ]
  }
]

export const AccountSettings = () => {
  const user = useSelector((state) => state.auth.account?.user)

  return (
    <>
      <ProfileHeader user={user} />

      {items.map(({ items }, index) => (
        <Section key={index}>
          {items.map(({ icon: Icon, to, name, className }, i) => (
            <Link
              key={i}
              to={to}
              className={cn('flex items-center gap-x-4 rounded-xl px-3 py-4 hover:bg-secondary-background', className)}
            >
              <Icon className="size-5" />
              <span className="grow">{name}</span>
            </Link>
          ))}
        </Section>
      ))}
    </>
  )
}
