import React from 'react'
import { ListContainer, Avatar, DateTime, AvatarImage, AvatarFallback } from 'Components'
import { useListItem } from 'Hooks'
// Import all necessary icons
import * as FiIcons from 'react-icons/fi'
import { ActionMenu } from './ActionsMenu'
import { getInitialsByName } from 'Utils'

// New component for rendering different column types
const ColumnContent = ({ type, columnKey, item }) => {
  const value = item[columnKey]
  switch (type) {
    case 'date':
      if (!value) return ''
      return (
        <span>
          <DateTime d={value} />
        </span>
      )
    case 'boolean':
      return value ? (
        <FiIcons.FiCheckCircle className="h-5 w-5 text-green-500" />
      ) : (
        <FiIcons.FiXCircle className="h-5 w-5 text-red-500" />
      )
    case 'multiSelect':
      return (
        <div className="flex flex-wrap gap-1">
          {value.map((item, index) => (
            <span
              key={index}
              className="px-2 py-1 text-xs font-medium bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 rounded-full"
            >
              {item}
            </span>
          ))}
        </div>
      )
    case 'avatar':
      return (
        <Avatar>
          <AvatarImage src={value} alt="User avatar" />
          <AvatarFallback>{getInitialsByName(item.userName)}</AvatarFallback>
        </Avatar>
      )
    default:
      return value
  }
}

const ListElem = ({ list, id, columns, actions }) => {
  const item = useListItem(list, id)
  return (
    <tr key={item[id || 'id']} className="border-border">
      {columns.map((column) => (
        <td key={column.key} className="px-6 py-4 whitespace-nowrap text-foreground">
          <ColumnContent type={column.type} columnKey={column.key} item={item} />
        </td>
      ))}
      <td className="px-6 py-4 whitespace-nowrap">
        <ActionMenu actions={actions} item={item} />
      </td>
    </tr>
  )
}

// Updated List component
export const ListTable = ({ list, ids, columns, actions, sortKey, isDesc }) => (
  <div className="bg-background border border-border shadow-md rounded-lg overflow-hidden">
    <table className="min-w-full divide-y divide-border">
      <thead className="bg-muted">
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase tracking-wider"
            >
              {column.label}
            </th>
          ))}
          <th className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-border">
        <ListContainer list={list} ids={ids} sortKey={sortKey} isDesc={isDesc}>
          <ListElem id="email" list={list} columns={columns} actions={actions} />
        </ListContainer>
      </tbody>
    </table>
  </div>
)
