import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'Components'
import * as FiIcons from 'react-icons/fi'

export const ActionMenu = ({ actions, item }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" className="h-8 w-8 p-0">
        <span className="sr-only">Open menu</span>
        <FiIcons.FiMoreVertical className="h-4 w-4" />
      </Button>
    </DropdownMenuTrigger>
    {actions && (
      <DropdownMenuContent align="end">
        {actions.map((action) => {
          const IconComponent = action.icon ? FiIcons[action.icon] : null
          return (
            <DropdownMenuItem key={action.name} onSelect={() => action.onAction(item)}>
              {IconComponent && <IconComponent className="h-4 w-4 mr-2" />}
              {action.name}
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    )}
  </DropdownMenu>
)
