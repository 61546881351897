export const CompanyInvitationCard = ({ invitation }) => {
  if (!invitation) return null
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div className="h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
          <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">Ai fost invitat să faci parte din</h3>
          <p className="text-sm text-gray-600">
            {invitation.company.cif} <b>{invitation.company.companyName}</b>
          </p>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <span className="text-sm text-gray-500">Oraș:</span>
          <span className="text-sm text-gray-900">{invitation.company.city}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-500">Județ:</span>
          <span className="text-sm text-gray-900">{invitation.company.county}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-500">Contact:</span>
          <span className="text-sm text-gray-900">{invitation.userName}</span>
          <span className="text-sm text-gray-900">{invitation.email}</span>
        </div>
      </div>
    </div>
  )
}

export const CompanyCard = ({ company }) => {
  if (!company) return null
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div>
          <h3 className="text-lg font-semibold text-gray-900">Firma</h3>
          <p className="text-sm text-gray-600">
            {company.cif} <b>{company.companyName}</b>
          </p>
          <p className="text-sm text-gray-600">
            {company.city}, {company.county}
          </p>
        </div>
      </div>
    </div>
  )
}
