import moment from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

// @INSTALL make sure you import all the locales you need as dynamically cannot be loaded in the browser
import 'dayjs/locale/de'

import utc from 'dayjs/plugin/utc.js'
import timezonePlugin from 'dayjs/plugin/timezone.js'
import { DEFAULT_TIMEZONE, SUPPORTED_TIMEZONES } from 'Constants'
import { getLowerCaseIndexOfArray } from 'Utils'

moment.extend(utc)
moment.extend(timezonePlugin)
moment.extend(localeData)

export { moment }

// extract the language part of the locale
export const getLanguageFromLocale = (locale) => {
  if (!locale) return ''
  return locale.split('-')[0]
}

export const getWeekInterval = (offset) => {
  offset = offset || 0
  let dayOffset = moment.localeData().firstDayOfWeek()
  if (!dayOffset) dayOffset = 1
  const fromTime = moment().startOf('week').add(dayOffset, 'days').add(offset, 'weeks')
  const toTime = fromTime.add(1, 'week')
  // Calculate the start of the week
  return { fromTime, toTime }
}

export const gmtOffset = (timezone) => {
  const thisTime = moment().tz(timezone || DEFAULT_TIMEZONE)
  const offsetInMinutes = thisTime.utcOffset()
  const offsetInHours = offsetInMinutes / 60

  return offsetInHours >= 0 ? `GMT+${offsetInHours}` : `GMT${offsetInHours}`
}

export const getMinutesFromTime = (time) => {
  time = time || '00:00'
  return (parseInt(time.substr(0, 2), 10) || 0) * 60 + (parseInt(time.substr(3, 2), 10) || 0)
}

export const getTimeFromMinutes = (minutes) => {
  minutes = minutes || 0
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
}

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours} hrs ${minutes} ${minutes === 1 ? 'min' : minutes === 0 ? '' : 'mins'}`
}

export const formatDeutsch = (fromTimeMoment) =>
  fromTimeMoment.format('DD') + '.' + fromTimeMoment.format('MMM').slice(0, 3) + '.'

export const goodTimezone = (timezone) => getLowerCaseIndexOfArray(SUPPORTED_TIMEZONES, timezone)

export const getTimeUntilExpiry = (expiresAt) => {
  const now = new Date()
  const expiry = new Date(expiresAt)
  const diff = expiry - now

  if (diff <= 0) return 'Expirat'

  const hours = Math.floor(diff / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

  return `${hours}:${minutes.toString().padStart(2, '0')}`
}
