import { Button, DateTime, Section } from 'Components'
import { FiLogIn, FiLogOut, FiTrash2 } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Actions } from 'Store'

export const CompanyDetails = () => {
  let { companyId } = useParams()

  const companies = useSelector((state) => state.auth.account?.userCompanies)
  const companyUserId = useSelector((state) => state.auth.account?.company?.companyUserId)
  const isActive = companyUserId === companyId

  const isOwner = false

  const { companyName, city, cif, county, createdAt } = companies.find((company) => company.companyUserId === companyId)

  return (
    <div className="space-y-5">
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{companyName}</h3>
        {isActive ? (
          <p className="text-sm/6 text-gray-500">This company</p>
        ) : (
          <Button onClick={() => Actions.selectCompany(companyId)} className="mt-1.5 flex gap-x-1.5">
            <FiLogIn className="size-4" />
            <span className="text-sm/6 font-medium">Login</span>
          </Button>
        )}
      </div>

      <Section>
        <dl className="grid grid-cols-2 gap-4 p-2.5">
          <dt className="text-sm/6 font-medium">CIF</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">{cif}</dd>
          <dt className="text-sm/6 font-medium">City</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">
            {city}, {county}
          </dd>
          <dt className="text-sm/6 font-medium">Creation date</dt>
          <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-0">
            <DateTime d={createdAt} />
          </dd>
        </dl>
      </Section>

      <Section>
        {isOwner ? (
          <SectionButton icon={FiTrash2} text="Delete company" />
        ) : (
          <SectionButton icon={FiLogOut} text="Leave company" />
        )}
      </Section>
    </div>
  )
}

const SectionButton = ({ icon: Icon, text }) => {
  return (
    <button
      onClick={() => {}}
      className="flex w-full items-center gap-x-4 rounded-xl px-3 py-4 text-destructive hover:bg-secondary-background"
    >
      <Icon className="size-5" />
      <span className="grow text-left">{text}</span>
    </button>
  )
}
