import * as React from 'react'
import {
  CompanySelector,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  useSidebar
} from 'Components'
import { ADMIN_SIDEBAR_ITEMS, APP_SIDEBAR_ITEMS, DEV_SIDEBAR_ITEMS } from 'Constants'
import { Link, useLocation } from 'react-router-dom'
import { FiArrowUpRight, FiHelpCircle, FiHome, FiMoreHorizontal, FiSettings } from 'react-icons/fi'
import { useApp } from 'Hooks'
import { isInternalRoute, route, routeIs } from 'Utils'

export function AppSidebar({ ...props }) {
  const { product } = useApp()

  const { isMobile } = useSidebar()
  if (isMobile) return null

  let items = []
  if (product === 'webapp') items = APP_SIDEBAR_ITEMS
  if (product === 'devplatform') items = DEV_SIDEBAR_ITEMS
  if (product === 'admintool') items = ADMIN_SIDEBAR_ITEMS

  return (
    <Sidebar variant="inset" collapsible="icon" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <CompanySelector />
          </SidebarMenuItem>
        </SidebarMenu>
        <SidebarMenu>
          <SidebarItem title="Dashboard" to={'home'} icon={FiHome} />
          <SidebarItem title="Setǎri" to={'settings.company'} icon={FiSettings} />
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarNav items={items} />
        {/* <NavProjects projects={data.projects} /> */}
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarItem title="Ajutor" to="https://factcurier.ro/help" icon={FiHelpCircle} />
          {/* <NavUser /> */}
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}

function SidebarItem({ title, to, icon: Icon, actions, count }) {
  const location = useLocation()
  const isActive = routeIs({ name: to, pathname: location.pathname })
  const isInternal = isInternalRoute(to)
  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild isActive={isActive} tooltip={title}>
        {isInternal ? (
          <Link to={route(to)}>
            {Icon && <Icon />}
            <span>{title}</span>
          </Link>
        ) : (
          <a href={to} target="_blank" rel="noreferrer">
            {Icon && <Icon />}
            <span>{title}</span>
          </a>
        )}
      </SidebarMenuButton>
      {actions?.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuAction className="peer" showOnHover>
              <FiMoreHorizontal />
              <span className="sr-only">More</span>
            </SidebarMenuAction>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48 rounded-lg">
            <DropdownMenuItem>
              {/* <Folder className="text-muted-foreground" /> */}
              <span>View Project</span>
            </DropdownMenuItem>
            <DropdownMenuItem>
              {/* <Forward className="text-muted-foreground" /> */}
              <span>Share Project</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      <SidebarItemBadge count={count} hasActions={!!actions?.length} isInternal={isInternal} />
    </SidebarMenuItem>
  )
}

function SidebarItemBadge({ count, hasActions, isInternal }) {
  if (!isInternal) {
    return (
      <SidebarMenuBadge>
        <FiArrowUpRight size={16} />
      </SidebarMenuBadge>
    )
  }
  if (count)
    return (
      <SidebarMenuBadge
        className={
          hasActions && 'group-focus-within/menu-item:hidden group-hover/menu-item:hidden group-has-[[data-state=open]]:hidden'
        }
      >
        {count}
      </SidebarMenuBadge>
    )
}

function SidebarNav({ items }) {
  return (
    <>
      {items.map((item, index) => (
        <SidebarGroup key={index}>
          {item.title && <SidebarGroupLabel>{item.title}</SidebarGroupLabel>}
          <SidebarMenu>
            {item.items.map((item2, index2) => (
              <SidebarItem key={index2} {...item2} />
            ))}
          </SidebarMenu>
        </SidebarGroup>
      ))}
    </>
  )
}
