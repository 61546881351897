// EntityActivityTimeline.js
import React, { useEffect } from 'react'
import { getEntityActivityIcon, getEntityActivityComponent, Avatar, AvatarImage, AvatarFallback } from 'Components'
import API from 'API'
import { Actions } from 'Store'
import { useList, useListItem } from 'Hooks'
import { getInitialsByName } from 'Utils'

const EntityActivityLogItem = ({ id }) => {
  const activityLog = useListItem('entityActivityLogs', id)
  if (!activityLog) return null

  return (
    <li className="mb-10 ms-6">
      <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
        {getEntityActivityIcon(activityLog.type)}
      </span>
      <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700">
        <div className="items-center justify-between sm:flex">
          <div className="flex-1">{getEntityActivityComponent(activityLog.type, activityLog.data)}</div>
          <div className="flex items-center gap-2 sm:ms-4">
            {activityLog.caller && (
              <div className="flex items-center gap-2">
                <Avatar className="h-6 w-6">
                  <AvatarImage src={activityLog.caller.avatarUrl} alt={activityLog.caller.name} />
                  <AvatarFallback className="text-xs">{getInitialsByName(activityLog.caller.name)}</AvatarFallback>
                </Avatar>
                <span className="text-sm text-gray-600 dark:text-gray-300">{activityLog.caller.name}</span>
              </div>
            )}
            <time className="text-xs font-normal text-gray-400">
              {new Date(parseInt(activityLog.ts)).toLocaleString('ro-RO')}
            </time>
          </div>
        </div>
      </div>
    </li>
  )
}

export const EntityActivityLogs = () => {
  const logs = useList('entityActivityLogs', 'ts', true)
  useEffect(() => {
    const syncList = async () => {
      const entityActivityLogs = await API._getEntityActivityLogs()
      if (entityActivityLogs?.error) return
      Actions.populateEntityActivityLogs(entityActivityLogs)
    }
    syncList()
  }, [])

  return (
    <>
      <h1 className="text-2xl font-semibold sm:tracking-tight">Jurnal activități</h1>
      <ol className="relative border-s border-gray-200 dark:border-gray-700">
        {logs.map((log, i) => (
          <EntityActivityLogItem id={log.id} key={i} />
        ))}
      </ol>
    </>
  )
}
