import '../site-common/index.css'
import { createRoot } from 'react-dom/client'
import { AdminApp, RootApp } from 'Components'

// --------------- common imports ---------------
import API from 'API'
import * as Constants from 'Constants'
import * as Hooks from 'Hooks'
import * as Components from 'Components'
import { Store, Actions, ReducerActions } from 'Store'
import * as Utils from 'Utils'

window.API = API
window.Utils = Utils
window.Constants = Constants
window.Hooks = Hooks
window.Actions = Actions
window.Components = Components
window.Store = Store
window.ReducerActions = ReducerActions
// -----------------------------------------------

const container = document.getElementById('app')
const app = createRoot(container)
app.render(
  <RootApp>
    <AdminApp />
  </RootApp>
)
