import API from 'API'
import { Actions, ReducerActions } from 'Store'

export const loadAdminScripts = () => {}

// will setup the initial store
export const loadAdminInitialData = async () => {}

// @INSTALL put here all the actions for the lists
export const populateAdminUsers = (adminUsers) => {
  ReducerActions._populateList({ list: 'adminUsers', items: adminUsers, idKey: 'email' })
}

export const upsertAdminUser = (adminUser) => {
  ReducerActions._upsertListItem({ list: 'adminUsers', item: adminUser, idKey: 'email' })
}

export const deleteAdminUser = (email) => {
  ReducerActions._deleteListItem({ list: 'adminUsers', id: email })
}

export const populateAdminApps = (apps) => {
  ReducerActions._populateList({ list: 'adminApps', items: apps, idKey: 'appUserId' })
}

export const upsertAdminApp = (app) => {
  ReducerActions._upsertListItem({ list: 'adminApps', item: app, idKey: 'appUserId' })
}

export const populateAdminHumanUsers = (adminHumanUsers) => {
  ReducerActions._populateList({ list: 'adminHumanUsers', items: adminHumanUsers, idKey: 'userId' })
}

export const populateAdminSessions = (adminSessions) => {
  ReducerActions._populateList({ list: 'adminSessions', items: adminSessions, idKey: 'jwtId' })
}

export const updateAdminApp = async ({ appId, isApproved }) => {
  const app = await API._updateAdminApp({ appId, isApproved })
  if (app?.error || !app) return
  await Actions.getMe()
}

export const approveOrDenyApp = async ({ appId, isApproved }) => {
  const isAck = await API._approveOrDenyApp({ appId, isApproved })
  if (!isAck) return
  await Actions.getMe({ onlyNotifications: true })
}
