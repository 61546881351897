import { useEffect } from 'react'
import { Actions } from 'Store'
import { CentralLoader, AppRouter, Main } from 'Components'
import { useAuthInit } from 'Hooks'

const useAppLocalStorage = () => {}

export const App = () => {
  useEffect(() => {
    const loadData = async () => {
      await Actions.loadAppInitialData()
      Actions.loadAppScripts()
    }
    loadData()
  }, [])

  useAppLocalStorage()
  useAuthInit()

  return (
    <Main loader={<CentralLoader />}>
      <AppRouter />
    </Main>
  )
}
