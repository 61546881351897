import { cn } from 'Utils'

export const Section = ({ title, children, className }) => {
  return (
    <div className="mt-5">
      <p className="text-sm">{title}</p>
      <div className={cn('mt-1 space-y-1 rounded-2xl bg-sidebar p-1', className)}>{children}</div>
    </div>
  )
}
