// calls the API with the given query and variables

import API from 'API'
import { Actions } from 'Store'
import { getProduct, wait } from 'Utils'

// call the API with the given query and variables
export const _callAPI = async ({ query, variables, isRetry, captchaToken, refreshToken, isPublic, allowPublic }) => {
  if (isPublic) allowPublic = true
  const headers = { 'Content-Type': 'application/json', 'x-product': getProduct() }
  if (allowPublic) headers['x-sd-allow'] = 'true'
  const body = JSON.stringify({ query, variables })
  // if it's not a public call, get the access token from the store
  const accessToken = !isPublic ? await API._getAccessToken() : null

  if (!allowPublic && !accessToken) return

  if (refreshToken) {
    headers.Authorization = `Refresh ${refreshToken}`
  } else if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  } else if (captchaToken) {
    headers.Authorization = `Captcha ${captchaToken}`
  }
  return fetch(process.env.REACT_APP_API_URL, { method: 'POST', body, headers })
    .then((response) => response.json())
    .then((result) => {
      // transform the result to have the errors in the same place
      const errors = result?.data?.errors || result?.errors || []
      if (errors[0]) {
        const code = errors[0].extensions?.code || ''
        const details = errors[0].extensions?.details || ''
        const message = errors[0].message || ''
        // the user has to log out immediately
        if (code === 'MUST_LOGOUT') Actions.logout(true)
        else return { error: { code, details, message } }
      }
      // console.info('API result', result?.data)
      return result?.data || {}
    })
    .catch((error) => {
      console.error('API error', error)
      return
    })
    .then((result) => {
      if (result?.error?.code === 'MUST_RETRY') {
        console.error('API retry', query, variables)
        if (isRetry) return { error: { code: 'SERVER_ERROR', message: 'Server error, please retry later' } }
        return wait(1).then(() => _callAPI({ query, variables, isRetry: true, captchaToken }))
      }
      return result
    })
}
