import util from 'util'
import { Buffer } from 'buffer'
import { customAlphabet } from 'nanoid'

export const wait = (s) =>
  new Promise((resolve) => {
    setTimeout(
      () => {
        resolve()
      },
      (s || 0) * 1000
    )
  })

export const clog = (tag, val) => {
  if (val) {
    console.info(tag, util.inspect(val, true, null))
    return val
  }
  return (value) => {
    console.info(tag, util.inspect(value, true, null))
    return value
  }
}

export const decodeX = (str) => Buffer.from(str || '', 'hex').toString('ascii')
export const encodeX = (str) => Buffer.from(str || '', 'ascii').toString('hex')

export const randomString = (length, alphabet) => {
  alphabet = alphabet || 'abcdefghijklmnopqrstuvwxyz'
  length = length || 10
  const nanoid = customAlphabet(alphabet, length)
  return nanoid()
}
