// parses the json safely
export const parseJSON = (str, withNull) => {
  const def = !withNull ? {} : null
  let res
  try {
    res = JSON.parse(str) || def
  } catch {
    return def
  }
  return res
}
