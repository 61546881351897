import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { Button, Form, FormControl, FormDescription, FormField, FormItem, FormMessage, PinCode } from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const validationSchema = z.object({
  pin: z.string(),
  serverError: z.string().optional()
})

// the executeRecaptcha is passed from the parent component
export const EmailSentForm = ({ executeRecaptcha }) => {
  const requestedEmail = useSelector((state) => state.auth.account.requestedEmail)
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)

  const defaultValues = useMemo(() => ({ pin: '', serverError: errorAuth?.message }), [errorAuth])

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const { setFocus, watch } = form

  const onSubmit = useCallback(
    async ({ pin }) => {
      const captchaToken = await executeRecaptcha?.('login')
      if (pin.length === 4) await Actions.loginWithEmailCode({ pin, email: requestedEmail, captchaToken })
    },
    [requestedEmail, executeRecaptcha]
  )

  const pin = watch('pin')

  const buttonDisabled = pin.length !== 4
  const buttonText = 'Autentificare'

  useEffect(() => {
    setFocus('pin')
  }, [setFocus])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Email trimis</h1>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        <div className="mb-2 block w-full text-sm text-gray-700">
          <div className="focus:shadow-outline mb-3 w-full rounded-xl border border-gray-300 px-3 py-2 leading-tight">
            {requestedEmail}
          </div>
        </div>
        <FormField
          control={form.control}
          name="pin"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormDescription className="mb-6 mt-9 text-center font-bold">
                Am trimis un cod de acces pe adresa ta de e-mail. Introdu codul de acces pentru a continua.
              </FormDescription>
              <FormControl>
                <PinCode
                  length={4}
                  type="number"
                  {...field}
                  className="flex justify-center gap-2"
                  pinClassName="mb-6 aspect-square w-max min-w-5 rounded-xl border text-center [appearance:textfield] placeholder:text-slate-300 dark:placeholder:text-slate-500 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {errorAuth && <p className="mt-3 text-sm text-red-500">{errorAuth?.message}</p>}
        <div className="mt-4 flex items-center justify-between">
          <Button type="submit" disabled={buttonDisabled} isLoading={isLoadingAuth} className="mr-2 flex-1">
            {buttonText}
          </Button>
        </div>
      </form>
    </Form>
  )
}
