import {
  FaUserPlus,
  FaUserMinus,
  FaUserEdit,
  FaLink,
  FaUnlink,
  FaUserCog,
  FaSignInAlt,
  FaUserShield,
  FaCode,
  FaUsers,
  FaGoogle,
  FaApple,
  FaLinkedin,
  FaUser,
  FaEnvelope,
  FaPhone
} from 'react-icons/fa'
import { booleanChanges, renderChange, renderRolesChanges } from './elems'

export const accountTypeConfig = {
  ADMINUSER: {
    icon: <FaUserShield className="h-4 w-4" />,
    label: 'Admin Tool',
    bgColor: 'bg-primary-admintool/10 dark:bg-primary-admintool/20',
    textColor: 'text-primary-admintool'
  },
  DEVELOPER: {
    icon: <FaCode className="h-4 w-4" />,
    label: 'Developer Platform',
    bgColor: 'bg-primary-devplatform/10 dark:bg-primary-devplatform/20',
    textColor: 'text-primary-devplatform'
  },
  EMPLOYEE: {
    icon: <FaUsers className="h-4 w-4" />,
    label: 'Web App',
    bgColor: 'bg-primary-webapp/10 dark:bg-primary-webapp/20',
    textColor: 'text-primary-webapp'
  }
}
export const deletionTypeConfig = {
  PLATFORM: {
    icon: <FaUserShield className="h-4 w-4" />,
    label: 'Admin tool',
    bgColor: 'bg-primary-admintool/10 dark:bg-primary-admintool/20',
    textColor: 'text-primary-admintool'
  },
  APP: {
    icon: <FaCode className="h-4 w-4" />,
    label: 'Developer Platform',
    bgColor: 'bg-primary-devplatform/10 dark:bg-primary-devplatform/20',
    textColor: 'text-primary-devplatform'
  },
  COMPANY: {
    icon: <FaUsers className="h-4 w-4" />,
    label: 'Web App',
    bgColor: 'bg-primary-webapp/10 dark:bg-primary-webapp/20',
    textColor: 'text-primary-webapp'
  },
  DIRECT: {
    icon: <FaUser className="h-4 w-4" />,
    label: 'Direct',
    bgColor: 'bg-primary-admintool/10 dark:bg-primary-admintool/20',
    textColor: 'text-primary-admintool'
  }
}

export const getActivityIcon = (type) => {
  switch (type) {
    case 'CREATED':
      return <FaUserPlus className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'UPDATED':
      return <FaUserEdit className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'DELETED':
      return <FaUserMinus className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'ACCOUNT_LOGIN':
      return <FaSignInAlt className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'ACCOUNT_LINKED':
      return <FaLink className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'ACCOUNT_UNLINKED':
      return <FaUnlink className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'ACCOUNT_PERMISSIONS_UPDATED':
      return <FaUserCog className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    default:
      return null
  }
}

export const CreatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
      <div className="mt-2 flex gap-2">
        {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
        {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
        {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
      </div>
    )}
  </div>
)

export const UpdatedActivity = ({ data }) => {
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300 space-y-1">
      {data.avatarUrl?.length === 2 && (
        <div className="flex items-center gap-4">
          <span className="flex items-center gap-2">
            <FaUser className="h-4 w-4 text-gray-400" />
            Avatar
          </span>
          <div className="flex items-center gap-2">
            {data.avatarUrl[0] && <img src={data.avatarUrl[0]} alt="Avatar vechi" className="h-8 w-8 rounded-full" />}
            <img src={data.avatarUrl[1]} alt="Avatar nou" className="h-8 w-8 rounded-full" />
          </div>
        </div>
      )}

      {data.userName?.length === 2 && renderChange(data.userName[0], data.userName[1], <FaUser className="h-4 w-4" />)}

      {data.email?.length === 2 && renderChange(data.email[0], data.email[1], <FaEnvelope className="h-4 w-4" />)}

      {data.phone?.length === 2 && renderChange(data.phone[0], data.phone[1], <FaPhone className="h-4 w-4" />)}

      {booleanChanges(data.hasGoogle, FaGoogle, ['Conectat', 'Deconectat'])}
      {booleanChanges(data.hasApple, FaApple, ['Conectat', 'Deconectat'])}
      {booleanChanges(data.hasLinkedin, FaLinkedin, ['Conectat', 'Deconectat'])}
    </div>
  )
}

export const DeletedActivity = ({ data }) => {
  const config = deletionTypeConfig[data.deletionType]
  return (
    <div className="text-sm text-red-500 dark:text-red-400">
      {config.icon && (
        <div className="mb-3 flex items-center gap-2">
          <span className={`flex items-center gap-2 rounded-md ${config.bgColor} ${config.textColor} px-2 py-1`}>
            {config.icon}
            <span>{config.label}</span>
          </span>
        </div>
      )}
      {!config.icon && <div className="mb-3 font-medium">Cont șters</div>}
      {data.userName && <div className="font-medium">{data.userName}</div>}
      {data.email && <div className="text-muted-foreground">({data.email})</div>}
      {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
      {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
        <div className="mt-2 flex gap-2">
          {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
          {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
          {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
        </div>
      )}
    </div>
  )
}

export const AccountLinkedActivity = ({ data }) => {
  const config = accountTypeConfig[data.accountType]
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="mb-3 flex items-center gap-2">
        <span className={`flex items-center gap-2 rounded-md ${config.bgColor} ${config.textColor} px-2 py-1`}>
          {config.icon}
          <span>{config.label}</span>
        </span>
        {data.company && (
          <span className="text-xs text-muted-foreground">
            {data.company.cif} {data.company.companyName}
          </span>
        )}
        {data.app && (
          <span className="text-xs text-muted-foreground">
            {data.app.appId} {data.app.appName}
          </span>
        )}
      </div>
      <div className="mb-2 flex gap-2">
        {data.roles.map((role) => (
          <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
            {role}
          </span>
        ))}
      </div>
      {data.isBlocked && (
        <span className="rounded bg-red-100 px-2.5 py-0.5 text-xs text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  )
}

export const AccountUnlinkedActivity = ({ data }) => {
  const config = accountTypeConfig[data.accountType]
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="mb-3 flex items-center gap-2">
        <span className={`flex items-center gap-2 rounded-md ${config.bgColor} ${config.textColor} px-2 py-1`}>
          {config.icon}
          <span>{config.label} deconectat</span>
        </span>
        {data.company && (
          <span className="text-xs text-muted-foreground">
            {data.company.cif} {data.company.companyName}
          </span>
        )}
        {data.app && (
          <span className="text-xs text-muted-foreground">
            {data.app.appId} {data.app.appName}
          </span>
        )}
      </div>
      <div className="mb-2 flex gap-2">
        {data.roles.map((role) => (
          <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
            {role}
          </span>
        ))}
      </div>
      {data.isBlocked && (
        <span className="rounded bg-red-100 px-2.5 py-0.5 text-xs text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  )
}

export const AccountPermissionsUpdatedActivity = ({ data }) => {
  const config = accountTypeConfig[data.accountType]
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="mb-3 flex items-center gap-2">
        <span className={`flex items-center gap-2 rounded-md ${config.bgColor} ${config.textColor} px-2 py-1`}>
          {config.icon}
          <span>{config.label}</span>
        </span>
        {data.company && (
          <span className="text-xs text-muted-foreground">
            {data.company.cif} {data.company.companyName}
          </span>
        )}
        {data.app && (
          <span className="text-xs text-muted-foreground">
            {data.app.appId} {data.app.appName}
          </span>
        )}
      </div>
      <div className="space-y-1">
        {data.changes.roles && (
          <div className="flex flex-col gap-1">
            <span className="text-xs text-muted-foreground">Roluri actualizate:</span>
            {renderRolesChanges(data.changes.roles[0], data.changes.roles[1])}
          </div>
        )}
        {data.changes.isBlocked && booleanChanges(data.changes.isBlocked, FaUserShield, ['Blocat', 'Deblocat'])}
      </div>
    </div>
  )
}

export const AccountLoginActivity = ({ data }) => {
  const config = accountTypeConfig[data.accountType]
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="mb-3 flex items-center gap-2">
        <span className={`flex items-center gap-2 rounded-md ${config.bgColor} ${config.textColor} px-2 py-1`}>
          {config.icon}
          <span>{config.label} logare reușită</span>
        </span>
        {data.company && (
          <span className="text-xs text-muted-foreground">
            {data.company.cif} {data.company.companyName}
          </span>
        )}
        {data.app && (
          <span className="text-xs text-muted-foreground">
            {data.app.appId} {data.app.appName}
          </span>
        )}
      </div>
      <div className="mb-2 flex gap-2">
        {data.roles.map((role) => (
          <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
            {role}
          </span>
        ))}
      </div>
      {data.isBlocked && (
        <span className="rounded bg-red-100 px-2.5 py-0.5 text-xs text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  )
}

export const getActivityComponent = (type, data) => {
  switch (type) {
    case 'CREATED':
      return <CreatedActivity data={data} />
    case 'UPDATED':
      return <UpdatedActivity data={data} />
    case 'DELETED':
      return <DeletedActivity data={data} />
    case 'ACCOUNT_LOGIN':
      return <AccountLoginActivity data={data} />
    case 'ACCOUNT_LINKED':
      return <AccountLinkedActivity data={data} />
    case 'ACCOUNT_UNLINKED':
      return <AccountUnlinkedActivity data={data} />
    case 'ACCOUNT_PERMISSIONS_UPDATED':
      return <AccountPermissionsUpdatedActivity data={data} />
    default:
      return null
  }
}
