import { HAS_LOCAL_STORAGE } from 'Constants'
import { useSelector } from 'react-redux'

// will keep the state slice in local storage
export const useLocalStorageSlice = (slice, whiteListKeys, blackListKeys) => {
  const state = useSelector((state) => state[slice])
  if (!HAS_LOCAL_STORAGE) return
  if (!slice) return

  if (!state || typeof state === 'string') {
    localStorage.removeItem(slice)
    return
  }

  const keys = Object.keys(state)
    .filter((key) => !whiteListKeys || whiteListKeys.includes(key))
    .filter((key) => !blackListKeys?.includes(key))

  const value = keys.reduce((acc, key) => {
    acc[key] = state[key]
    return acc
  }, {})
  localStorage.setItem(slice, JSON.stringify(value))
}
