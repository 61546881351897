// entityActivityTypes.js
import { FaBuilding, FaEdit, FaTrash, FaLink, FaUnlink, FaUserCog, FaSignInAlt, FaUserShield } from 'react-icons/fa'
import { booleanChanges, renderRolesChanges } from './elems'

export const getEntityActivityIcon = (type) => {
  switch (type) {
    case 'COMPANY_CREATED':
    case 'APP_CREATED':
      return <FaBuilding className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'COMPANY_UPDATED':
    case 'APP_UPDATED':
      return <FaEdit className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'COMPANY_DELETED':
    case 'APP_DELETED':
      return <FaTrash className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'USER_LINKED':
      return <FaLink className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'USER_UNLINKED':
      return <FaUnlink className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'USER_PERMISSIONS_UPDATED':
      return <FaUserCog className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    case 'USER_LOGIN':
      return <FaSignInAlt className="h-3 w-3 text-blue-600 dark:text-blue-300" />
    default:
      return null
  }
}

export const CompanyCreatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="font-medium">Companie nouă creată</div>
    <div className="text-muted-foreground">
      #{data.cif} {data.companyName}
      {data.isVerified && (
        <span className="ml-2 rounded bg-green-100 px-2.5 py-0.5 text-xs text-green-600 dark:bg-green-900 dark:text-green-300">
          Verificată
        </span>
      )}
    </div>
  </div>
)

export const CompanyUpdatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300 space-y-1">
    {Object.entries(data).map(([key, [oldValue, newValue]]) => (
      <div key={key} className="flex items-center gap-2">
        <span className="font-medium">{key}:</span>
        <span className="line-through text-red-500 dark:text-red-400">{oldValue}</span>
        <span className="text-green-500 dark:text-green-400">{newValue}</span>
      </div>
    ))}
  </div>
)

export const CompanyDeletedActivity = ({ data }) => {
  return (
    <div className="text-sm text-red-500 dark:text-red-400">
      Companie ștearsă
      <div className="text-muted-foreground">
        {data.companyName} #{data.cif}
        {data.isVerified && (
          <span className="ml-2 rounded bg-green-100 px-2.5 py-0.5 text-xs text-green-600 dark:bg-green-900 dark:text-green-300">
            Verificată
          </span>
        )}
      </div>
    </div>
  )
}

export const AppCreatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="font-medium">Aplicație nouă creată</div>
    <div className="text-muted-foreground">
      #{data.appId} {data.appName}
    </div>
  </div>
)

export const AppUpdatedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300 space-y-1">
    {Object.entries(data).map(([key, [oldValue, newValue]]) => (
      <div key={key} className="flex items-center gap-2">
        <span className="font-medium">{key}:</span>
        <span className="line-through text-red-500 dark:text-red-400">{oldValue}</span>
        <span className="text-green-500 dark:text-green-400">{newValue}</span>
      </div>
    ))}
  </div>
)

export const AppDeletedActivity = ({ data }) => (
  <div className="text-sm text-red-500 dark:text-red-400">
    Aplicație ștearsă
    <div className="text-muted-foreground">
      #{data.appId} {data.appName}
    </div>
  </div>
)

export const UserLinkedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="text-xs text-muted-foreground mb-2">#{data.userId} adăugat</div>
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
      <div className="mt-2 flex gap-2">
        {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
        {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
        {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
      </div>
    )}
    <div className="mb-2 flex gap-2">
      {data.roles.map((role) => (
        <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
          {role}
        </span>
      ))}
    </div>
    <div className="text-xs text-muted-foreground">
      User ID: {data.userId}
      {data.isBlocked && (
        <span className="ml-2 rounded bg-red-100 px-2.5 py-0.5 text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  </div>
)

export const UserPermissionsUpdatedActivity = ({ data }) => {
  return (
    <div className="text-sm text-gray-500 dark:text-gray-300">
      <div className="text-xs text-muted-foreground mb-2">#{data.userId} a actualizat permisiunile</div>
      <div className="font-medium">{data.userName}</div>
      <div className="text-muted-foreground">({data.email})</div>
      {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
      {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
        <div className="mt-2 flex gap-2">
          {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
          {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
          {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
        </div>
      )}
      <div className="space-y-1">
        {data.changes.roles && (
          <div className="flex flex-col gap-1">
            <span className="text-xs text-muted-foreground">Roluri actualizate:</span>
            {renderRolesChanges(data.changes.roles[0], data.changes.roles[1])}
          </div>
        )}
        {data.changes.isBlocked && booleanChanges(data.changes.isBlocked, FaUserShield, ['Blocat', 'Deblocat'])}
      </div>
    </div>
  )
}

export const UserUnlinkedActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="text-muted-foreground">#{data.userId} deconectat</div>
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
      <div className="mt-2 flex gap-2">
        {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
        {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
        {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
      </div>
    )}
    <div className="mb-2 flex gap-2">
      {data.roles.map((role) => (
        <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
          {role}
        </span>
      ))}
    </div>
    <div className="text-xs text-muted-foreground">
      {data.isBlocked && (
        <span className="ml-2 rounded bg-red-100 px-2.5 py-0.5 text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  </div>
)

export const UserLoginActivity = ({ data }) => (
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div className="text-muted-foreground">#{data.userId} s-a logat</div>
    <div className="font-medium">{data.userName}</div>
    <div className="text-muted-foreground">({data.email})</div>
    {data.phone && <div className="text-muted-foreground">{data.phone}</div>}
    {(data.hasApple || data.hasGoogle || data.hasLinkedin) && (
      <div className="mt-2 flex gap-2">
        {data.hasApple && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Apple</span>}
        {data.hasGoogle && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">Google</span>}
        {data.hasLinkedin && <span className="rounded bg-gray-100 px-2.5 py-0.5 text-xs dark:bg-gray-600">LinkedIn</span>}
      </div>
    )}
    <div className="mb-2 flex gap-2">
      {data.roles.map((role) => (
        <span key={role} className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium dark:bg-gray-600">
          {role}
        </span>
      ))}
    </div>
    <div className="text-xs text-muted-foreground">
      User ID: {data.userId}
      {data.isBlocked && (
        <span className="ml-2 rounded bg-red-100 px-2.5 py-0.5 text-red-600 dark:bg-red-900 dark:text-red-300">Blocat</span>
      )}
    </div>
  </div>
)

export const getEntityActivityComponent = (type, data) => {
  switch (type) {
    case 'COMPANY_CREATED':
      return <CompanyCreatedActivity data={data} />
    case 'COMPANY_UPDATED':
      return <CompanyUpdatedActivity data={data} />
    case 'COMPANY_DELETED':
      return <CompanyDeletedActivity />
    case 'APP_CREATED':
      return <AppCreatedActivity data={data} />
    case 'APP_UPDATED':
      return <AppUpdatedActivity data={data} />
    case 'APP_DELETED':
      return <AppDeletedActivity />
    case 'USER_LINKED':
      return <UserLinkedActivity data={data} />
    case 'USER_PERMISSIONS_UPDATED':
      return <UserPermissionsUpdatedActivity data={data} />
    case 'USER_UNLINKED':
      return <UserUnlinkedActivity data={data} />
    case 'USER_LOGIN':
      return <UserLoginActivity data={data} />
    default:
      return null
  }
}
