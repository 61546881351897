import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, Input, Section } from 'Components'
import { useForm } from 'react-hook-form'

export const NewClient = () => {
  const form = useForm()
  return (
    <div>
      <h1 className="text-2xl font-semibold sm:tracking-tight">Create a new client</h1>

      <Form {...form}>
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="shadcn" {...field} />
              </FormControl>
              <FormDescription>This is your public display name.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cui"
          render={({ field }) => (
            <FormItem>
              <FormLabel>CUI</FormLabel>
              <FormControl>
                <Input placeholder="3820918903" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" placeholder="Email" {...field} />
              </FormControl>
              <FormDescription>This is your public display name.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </div>
  )
}
