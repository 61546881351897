import { Section } from 'Components'
import { SUPPORTED_TIMEZONES } from 'Constants'
import { FiCheck } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const TimezoneSelector = () => {
  const currentTimezone = useSelector((state) => state.locales.timezone)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Choose a timezone</h3>
      <Section>
        {SUPPORTED_TIMEZONES.map((timezone, i) => (
          <button
            key={i}
            onClick={() => Actions.setTimezone(timezone)}
            className="flex w-full items-center justify-between gap-x-4 rounded-xl px-3 py-4 text-left hover:bg-secondary-background"
          >
            {timezone}
            {currentTimezone === timezone && <FiCheck className="size-5" />}
          </button>
        ))}
      </Section>
    </>
  )
}
