import { useEffect } from 'react'
import { Actions } from 'Store'
import { CentralLoader, DevRouter, Main } from 'Components'
import { useAuthInit } from 'Hooks'

const useDevLocalStorage = () => {}

export const DevApp = () => {
  useEffect(() => {
    const loadData = async () => {
      await Actions.loadDevInitialData()
      Actions.loadDevScripts()
    }
    loadData()
  }, [])

  useDevLocalStorage()
  useAuthInit()

  return (
    <Main loader={<CentralLoader />}>
      <DevRouter />
    </Main>
  )
}
