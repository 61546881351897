import { DEFAULT_LANGUAGE } from 'Constants'
import { useLocalStorageSlice } from 'Hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import { getLanguageFromLocale } from 'Utils'

let defs = {}
// loads the translations and caches for a specific locale
const getLanguageDefs = async (language) => {
  if (!defs[language]) {
    // make sure to get the latest version of the file
    const timestamp = new Date().getTime()
    const defsLang = await fetch(`${process.env.REACT_APP_PUBLIC_URL || ''}/locales/${language}.json?v=${timestamp}`)
      .then((res) => res.text())
      .then((d) => (d && JSON.parse(d)) || {})
      .catch(() => ({}))
    defs[language] = defsLang
  }
  // merge the default locale with the current one, so we get the default locale if the key is not defined
  return defs[language]
}

// the locales provider will set the locale and timezone
export const LocalesProvider = ({ children }) => {
  useLocalStorageSlice('locales', ['locale', 'timezone'])
  const locale = useSelector((state) => state.locales.locale)
  useEffect(() => {
    Actions.setLocale()
    Actions.setTimezone()
  }, [])

  useEffect(() => {
    if (!locale) return
    getLanguageDefs(DEFAULT_LANGUAGE).then((defaultDefs) =>
      getLanguageDefs(getLanguageFromLocale(locale)).then((defs) => {
        Actions.setLanguageKeys({ ...defaultDefs, ...defs })
      })
    )
  }, [locale])

  return children
}
