export const AppSpv = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Autorizare SPV</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nulla exercitationem
        recusandae non ullam quisquam, eveniet beatae illo culpa at, odit quasi illum? Vero quidem dolorem velit doloribus odit
        libero doloremque placeat enim ad magni nisi inventore facere porro laborum quo id debitis ut nobis amet, vel explicabo
        nostrum expedita? Odio, veritatis? Commodi sit magnam exercitationem, modi, accusamus dolor ab eveniet voluptas veritatis
        saepe tempora, dolores ea temporibus eos possimus maxime. Veritatis, laborum aliquam, ipsa rerum quisquam beatae optio
        dignissimos ad pariatur ratione libero dicta voluptate! Nostrum nesciunt libero corporis culpa provident error totam
        consequuntur neque! Voluptatem veniam fugiat dignissimos nesciunt iusto facere harum reiciendis repudiandae nihil minus
        quidem doloremque dicta laboriosam, saepe incidunt similique perspiciatis officia enim neque excepturi sequi illo sed?
        Vero explicabo eligendi itaque sapiente praesentium perferendis placeat, nobis suscipit, est molestias eaque velit enim
        odio voluptatibus beatae voluptatem neque, voluptas corrupti aliquam. Similique ut asperiores sequi modi maiores labore
        architecto accusantium soluta officia? Dicta voluptate pariatur dignissimos placeat corrupti sint nam numquam quibusdam,
        minus sapiente quas quam magni laudantium! Saepe pariatur quod voluptatem rerum! Est eveniet iste blanditiis omnis,
        nostrum accusantium? Fugiat dolorem rerum ipsum quaerat aspernatur laboriosam quod, molestiae exercitationem sit, ut porro
        quo ipsam nobis molestias, officiis corrupti similique! Laboriosam, necessitatibus earum quas voluptatem saepe numquam
        nihil autem esse assumenda debitis suscipit repudiandae omnis! Repellendus consequuntur veritatis excepturi facilis
        asperiores quod quia quis libero hic commodi. Tempora exercitationem accusamus quia eius voluptas vitae explicabo
        consectetur ut nostrum culpa quod nobis iure unde fuga incidunt provident, sunt cupiditate, enim suscipit velit ratione
        eos repellat doloribus. Accusamus consequuntur optio aliquam delectus vero amet sit magnam placeat voluptatibus. Odio non
        quis, iure ullam perferendis animi aliquam harum tempore blanditiis maxime, impedit amet! Voluptas magnam quia numquam sit
        nihil odit minima tenetur. At vitae ducimus maxime voluptates cumque in, possimus commodi! Accusamus tempore voluptatibus
        doloremque, deleniti totam ducimus, delectus quis, nobis quos explicabo pariatur natus iste corporis quae. Deleniti
        voluptatibus culpa dignissimos aliquam? Culpa et, similique exercitationem iste dolore enim molestias, repudiandae ducimus
        aspernatur consequatur, vero nam fuga! Dolores aliquam a qui ex autem, molestiae reiciendis beatae fuga inventore illum,
        quod eos quae vitae rerum voluptatibus optio odio, quo nemo placeat amet vero soluta animi quibusdam omnis? Fuga quidem
        temporibus atque quasi, sit laboriosam ab quas esse doloribus molestias officiis nam fugiat aliquid quos harum est
        ratione. Incidunt error iure optio rerum beatae quisquam voluptate aliquam exercitationem ducimus, perspiciatis nesciunt,
        architecto, suscipit quaerat reiciendis nulla eveniet. Corporis harum voluptates officia nobis quibusdam placeat modi
        nesciunt quod autem blanditiis earum, ipsam, eligendi cumque minima dolores accusamus accusantium voluptate a tempore hic
        pariatur ducimus assumenda! Aut repudiandae totam, cum voluptate, molestias dolorum magni deserunt porro id facere
        accusantium nisi cupiditate nobis cumque, necessitatibus incidunt! Ea quam libero vitae modi eveniet autem possimus optio,
        blanditiis aut neque? Inventore rerum debitis animi hic repellat aperiam expedita culpa est nam! Distinctio nostrum atque
        dolores adipisci pariatur consequatur, perspiciatis, possimus rem odio, sapiente delectus asperiores porro consequuntur?
        Autem labore expedita ab tempore, quas libero, maxime, asperiores architecto sint non hic quasi nisi sed provident qui
        quam rerum dolorem explicabo sequi cupiditate eveniet ullam. Excepturi voluptatibus minus nostrum animi quo? Minus
        incidunt aspernatur obcaecati fugit earum et error quas eligendi? Voluptates obcaecati provident quasi at minima laborum
        quas veritatis consectetur sunt repellat omnis, nesciunt mollitia. Ex harum porro hic necessitatibus et minima distinctio
        autem mollitia reiciendis laudantium enim voluptatem commodi nam aut, doloribus neque voluptates dolor aliquam iusto. Quo
        dolor numquam necessitatibus deleniti nihil. Repudiandae dolor, necessitatibus assumenda vero numquam quisquam quam unde
        repellat, eos suscipit libero iusto minus beatae. Quibusdam quae, enim laboriosam aliquid, expedita minus vitae numquam
        dolorum laborum alias, dicta fuga! Molestiae eveniet autem architecto mollitia necessitatibus est, blanditiis porro
        molestias? Non reiciendis accusamus error quisquam maxime aut illum minima qui nisi ad voluptas, saepe nulla, architecto
        velit labore illo sit ullam laborum dignissimos. Numquam cupiditate assumenda perferendis nostrum hic voluptatum cumque,
        eligendi blanditiis iure consectetur similique provident, necessitatibus aliquam nam, autem quasi. Obcaecati qui maiores
        itaque fuga quaerat quas atque id harum perferendis rem aspernatur, labore numquam nemo. Deleniti, repudiandae! Veniam
        quia facilis, quam ex pariatur vero neque culpa exercitationem. Officia, temporibus soluta nisi praesentium beatae optio
        amet laborum quae blanditiis numquam dolorum, quasi ipsum impedit vero aliquid? Sed illum explicabo non voluptatum ipsa
        molestiae cupiditate tempora voluptatem repudiandae! Velit quibusdam deleniti possimus tempora quos illo quae nisi
        delectus quo? Repellendus nemo nisi vel quisquam neque. Illo accusantium facilis culpa? Sequi commodi nemo sapiente
        accusamus aspernatur optio soluta voluptatum. Inventore, ducimus deleniti facere hic repellendus voluptatem reprehenderit
        ipsam provident soluta, cum magnam laudantium est ullam error accusantium autem ad ipsum minus, debitis fugit architecto
        alias fuga cumque explicabo. Nam obcaecati, nemo quae corporis laudantium consectetur reprehenderit itaque eum deserunt
        quasi aliquam, sit voluptas quas, qui vitae dolores. Possimus, aperiam quos maiores exercitationem et sapiente nisi eius
        vitae eveniet obcaecati enim tempore eaque placeat? Velit tenetur iusto earum aut incidunt quibusdam, nemo distinctio
        optio unde eveniet hic fuga blanditiis similique porro nostrum odit molestias at eaque provident laudantium assumenda quo?
        Velit voluptate saepe provident deserunt placeat ex, ad perspiciatis ipsum inventore quis, explicabo quod vitae neque
        minus ab delectus omnis molestiae porro architecto voluptates. Provident excepturi ab vel corporis nihil exercitationem,
        et quidem reprehenderit alias consequatur expedita eum pariatur eligendi voluptatum fugit perferendis nobis mollitia cum
        quo sapiente impedit. Ipsam deleniti, amet dignissimos iusto maxime nihil tempore, deserunt velit eum voluptate possimus
        non aliquam, dolor fugit neque. Amet cum nihil atque veritatis nulla corporis recusandae enim minima at saepe minus soluta
        ab impedit iste nam molestiae dolore reprehenderit id, quae in eveniet. Sunt eaque officiis neque aperiam repudiandae
        obcaecati autem? Porro iure nam ipsum unde quos, optio illum corrupti ut error est, aperiam accusamus ratione cum dolores?
        Consectetur, aut esse? Qui eaque nesciunt, suscipit incidunt corrupti, officiis voluptatem nihil repudiandae quasi
        expedita quaerat ipsum et! Voluptatum earum pariatur dolorem omnis iste in necessitatibus consequatur ex sint, minus
        incidunt distinctio dignissimos eveniet rem corporis optio dolore, ea unde sunt placeat accusantium non aliquid?
      </p>
    </div>
  )
}
