export const baseInitialState = {
  isLoadingApp: true,
  theme: 'light',
  showToast: false,
  toastMessage: '',
  toastVariant: 'default', // default, error, warning, info, success
  toastDuration: 3000
}

export const baseReducers = {
  _setIsLoadingApp: (state, action) => {
    state.isLoadingApp = !!action.payload
  },
  _setTheme: (state, action) => {
    state.theme = action.payload
  },
  _showToast: (state, action) => {
    state.showToast = true
    state.toastMessage = action.payload.message
    state.toastVariant = action.payload.variant || baseInitialState.toastVariant
    state.toastDuration = action.payload.duration || baseInitialState.toastDuration
  },
  _hideToast: (state) => {
    state.showToast = false
    state.toastMessage = ''
    state.toastVariant = baseInitialState.toastVariant
    state.toastDuration = baseInitialState.toastDuration
  }
}
