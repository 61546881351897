import { HAS_LOCAL_STORAGE } from 'Constants'
import { parseJSON } from './dom-manipulations'

export const getLocalStorageKey = (key) => {
  if (!HAS_LOCAL_STORAGE) return
  return parseJSON(localStorage.getItem(key))
}

export const getLocalStorageString = (key) => {
  if (!HAS_LOCAL_STORAGE) return
  return localStorage.getItem(key) || ''
}

export const getQueryParamsKey = (key) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(key)
}

export const setQueryParam = (key, value) => {
  if (!key) return
  const url = new URL(window.location.href)
  if (value) url.searchParams.set(key, value)
  else url.searchParams.delete(key)
  const newUrl = url.pathname + url.search + url.hash
  window.history.replaceState({}, document.title, newUrl)
}
