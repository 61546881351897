import React from 'react'
import { ListContainer } from 'Components'
import { useListItem } from 'Hooks'
import { ActionMenu } from './ActionsMenu'

const ListElem = ({ list, id, actions, ItemComponent, action }) => {
  const item = useListItem(list, id)
  return (
    <div className="relative flex items-center justify-between p-4 border-b border-border">
      <div className="flex-1">
        <ItemComponent item={item} />
      </div>
      {actions && (
        <div className="flex-shrink-0 ml-4">
          <ActionMenu actions={actions} item={item} />
        </div>
      )}
      {action && action(item)}
    </div>
  )
}

export const ListCard = ({ list, id, ids, actions, action, sortKey, isDesc, ItemComponent }) => (
  <div className="divide-y divide-border rounded-md">
    <ListContainer list={list} ids={ids} sortKey={sortKey} isDesc={isDesc}>
      <ListElem id={id} list={list} actions={actions} action={action} ItemComponent={ItemComponent} />
    </ListContainer>
  </div>
)
