import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import PusherClient from 'pusher-js/with-encryption'
import { getProduct } from 'Utils'

// receive the session from the server
export const PusherProvider = ({ children }) => {
  const entityUserId = useSelector((state) => state.auth.account.company?.companyUserId || state.auth.account.app?.appUserId)
  const userId = useSelector((state) => state.auth.account.userId)
  const accessToken = useSelector((state) => state.auth.accessToken)
  const [lastSubscribedIds, setLastSubscribedIds] = useState([])

  useEffect(() => {
    const channels = [entityUserId, userId].filter(Boolean)
    if (!channels.length) return
    // find the difference between the last subscribed ids and the new channels
    const unsubscribeIds = lastSubscribedIds.filter((id) => !channels.includes(id))
    unsubscribeIds.forEach((id) => window.pusherClient.unsubscribe(`private-encrypted-${id}`))
    console.info('====unsubscribeIds', unsubscribeIds)

    // Disconnect any existing client before creating a new one
    if (window.pusherClient) window.pusherClient.disconnect()

    window.pusherClient = process.env.REACT_APP_PUSHER_ID
      ? new PusherClient(process.env.REACT_APP_PUSHER_ID, {
          cluster: 'eu',
          authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
          auth: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'x-product': getProduct()
            }
          }
        })
      : null

    if (!window.pusherClient) return
    const subscribeIds = channels.filter((id) => !lastSubscribedIds.includes(id))
    setLastSubscribedIds(channels)
    // subscribe to the new channels
    subscribeIds.forEach((id) =>
      window.pusherClient.subscribe(`private-encrypted-${id}`).bind('actions', ({ name, data } = {}) => {
        Actions.onPusherMessageArrived({ id, name, data })
      })
    )
    console.info('====subscribedIds', subscribeIds)
    // only when the token changes we need to re-subscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return children
}
