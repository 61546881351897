import API from 'API'
import { Actions } from 'Store'

export const loadDevScripts = () => {}

// will setup the initial store
export const loadDevInitialData = async () => {}

// saves the user profile
export const registerApp = async (appData) => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._registerApp(appData)
  Actions.onNewTokensArrived(authTokens)
}

export const selectApp = async (appUserId) => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._selectApp(appUserId)
  Actions.onNewTokensArrived(authTokens)
}

export const inviteDeveloper = async () => {
  const result = await API._inviteDeveloper()
  return result
}

// when closing the invite modal
export const inviteClose = () => Actions.denyInvitation()
