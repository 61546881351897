import { romanianCompanyFields, getAuthTokensFields } from 'API'

export const registerCompanyQuery = `
  mutation registerCompany($companyData: CompanyPayload!) {
    registerCompany(companyData: $companyData) ${getAuthTokensFields}
  }`

export const selectCompanyQuery = `
  mutation selectCompany($companyUserId: ID!) {
    selectCompany(companyUserId: $companyUserId) ${getAuthTokensFields}
  }
`
export const getRomanianCompanyByCUIQuery = `
  query getRomanianCompanyByCUI($cif: ID!) {
    getRomanianCompanyByCUI(cif: $cif) ${romanianCompanyFields}
  }
`

export const searchRomanianCompaniesQuery = `
  query searchRomanianCompanies($text: String) {
    searchRomanianCompanies(text: $text) ${romanianCompanyFields}
  }
`

export const inviteEmployeeQuery = `
  mutation inviteEmployee {
    inviteEmployee
  }
`
